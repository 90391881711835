import React, { useState, useEffect } from "react";
import "../../styles/alarmDetail.css";
import MainHeader from "../MainHeader";
import "../../styles/site.css";
import { withRouter } from "react-router-dom";
import { updateSite, getUnassignedSites } from "../../utils/apis";

function SiteDetailForm(props) {

  const [id, setId] = useState("");
  const [newSite, setNewSite] = useState(false);
  const [unassignedSites, setUnassignedSites] = useState(null);

  const [formState, setFormState] = useState({
    macId: "",
    siteName: "",
    siteAddress: "",
    longitude: "",
    latitude: "",
    accessNotes: "",
    contactName: "",
    email: "",
    phone: "",
    contactNotes: "",
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { macId, siteName, siteAddress, longitude, latitude, accessNotes, contactName, email, phone, contactNotes } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      const unassignedSites = await getUnassignedSites();
      if (props.history.location.pathname.includes("/sites/detail/new")) {
        if (unassignedSites) {
          setUnassignedSites(unassignedSites);
        } 
        setNewSite(true);
      } else if (props.site) {
        if (unassignedSites) {
          unassignedSites.unshift({ site_mac_id: props.site.macId });
          setUnassignedSites(unassignedSites);
        } 
        setId(props.site.id);
        setFormState({ ...formState, ...props.site });
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!macId) {
      setMessage({ messageText: "You must enter a site id.", messageColor: "red" });
      return;
    }

    if (!siteName.trim()) {
      setMessage({ messageText: "You must enter a site name.", messageColor: "red" });
      return;
    }

    if (latitude && latitude.length > 0) {
      // removes non-numeric characters from lat
      const latitude = formState.latitude.replace(/[^0-9.-]/g, "");

      // checks if lat in valid range
      if (!latitude || latitude > 90 || latitude < -90) {
        setMessage({ messageText: "Latitude must be between -90 and 90.", messageColor: "red" });
        return;
      }
    }

    if (longitude && longitude.length > 0) {
      // removes non-numeric characters from lon
      const longitude = formState.longitude.replace(/[^0-9.-]/g, "");

      // checks if lon in valid range
      if (!longitude || longitude > 180 || longitude < -180) {
        setMessage({ messageText: "Longitude must be between -180 and 180.", messageColor: "red" });
        return;
      }
    }

    let method;

    if (id) {
      method = "PUT";
    } else {
      method = "POST";
    }

    const data = {
      id,
      macId,
      siteName: siteName.trim(),
      siteAddress: siteAddress.trim(),
      longitude,
      latitude,
      accessNotes,
      contactName: contactName.trim(),
      email: email.trim(),
      phone: phone.trim(),
      contactNotes,
    }

    const updatedSite = await updateSite(method, data);

    if (updatedSite && updatedSite.err) {
      if (updatedSite.err === "This site id is not available.") {
        setMessage({ messageText: "This site id is not available. Please try again.", messageColor: "red" });
      } else {
        setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
      }
      return;
    }
    else if (
      updatedSite && updatedSite.data &&
      props.history.location.pathname.includes("/sites/detail/new")
    ) {
      props.history.push("/sites");
    } else if (updatedSite && updatedSite.data) {

      if (updatedSite.data.siteIpInfo) {
        props.storeSite({
          ...formState, siteIp: updatedSite.data.siteIpInfo.siteIpAddress, 
        });
      } else {
        props.storeSite({
          ...formState
        });
      }
    } else {
      setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
      return;
    } 
  };

  const cancelForm = () => {
    if (props.cancelForm) {
      props.cancelForm();
    } else {
      props.history.push("/sites");
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader componentName="Site Details" />
      <form id="site-form" onSubmit={handleSubmit}>
        <div className="alarm-detail-section">
          <p className="heading"> SITE </p>
          {unassignedSites && newSite && (
            <p>
              <span className="detail">
                ID<span className="red">*</span>:{" "}
              </span>
              <select
                name="macId"
                value={macId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                {unassignedSites.map((site, i) => (
                  <option key={i} value={site.site_mac_id}>
                    {site.site_mac_id}
                  </option>
                ))}
              </select>
            </p>
          )}
          {!unassignedSites && newSite && (
            <p>
              <span className="detail">
                Name<span className="red">*</span>:{" "}
              </span>
              <select
                name="macId"
                value={macId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                <option value="">
                  You do not have any unassigned sites.
                </option>
              </select>
            </p>
          )}

          {unassignedSites && !newSite && (
            <p>
              <span className="detail">
                ID<span className="red">*</span>:{" "}
              </span>
              <select
                name="macId"
                value={macId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                {unassignedSites.map((site, i) => (
                  <option key={i} value={site.site_mac_id}>
                    {site.site_mac_id}
                  </option>
                ))}
              </select>
            </p>
          )}
          {!unassignedSites && !newSite && (
            <p>
              <span className="detail">
                Name<span className="red">*</span>:{" "}
              </span>
              <select
                name="macId"
                value={macId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                <option value={macId}>{macId}</option>
              </select>
            </p>
          )}

          <p>
            <span className="detail">
              Name<span className="red">*</span>:{" "}
            </span>
            <input
              type="text"
              name="siteName"
              value={siteName}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Address: </span>
            <input
              type="text"
              name="siteAddress"
              value={siteAddress}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Latitude: </span>
            <input
              type="text"
              name="latitude"
              value={latitude}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Longitude: </span>
            <input
              type="text"
              name="longitude"
              value={longitude}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Access Notes: </span>
            <textarea
              className="site-form-textarea"
              type="text"
              name="accessNotes"
              maxLength="1000"
              value={accessNotes}
              onChange={handleChange}
            />
          </p>
          <div className="shadow-box"></div>
          <p className="heading"> CONTACT </p>
          <p>
            <span className="detail">Contact: </span>
            <input
              type="text"
              name="contactName"
              value={contactName}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Email: </span>
            <input
              type="text"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Phone: </span>
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Contact Notes: </span>
            <textarea
              className="site-form-textarea"
              type="text"
              maxLength="1000"
              name="contactNotes"
              value={contactNotes}
              onChange={handleChange}
            />
          </p>
          <button className="details-submit">Submit</button>
          <div
            className="details-form-footer"
            onClick={() => cancelForm()}
          >
            Cancel
          </div>
          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default withRouter(SiteDetailForm);
