import React, {useState} from "react";
import auth from "../../utils/auth";
import logo from "../../images/logo.png";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../styles/auth.css";

function PasswordResetForm() {

    const history = useHistory();
    const { token } = useParams();

    const [forgetPswdLink, setForgetPswdLink] = useState(false);
    const [formState, setFormState] = useState({
        passwordOne: "",
        passwordTwo: ""
    });
    const [message, setMessage] = useState({ messageText: "", messageColor: "" });
  
    const { passwordOne, passwordTwo } = formState;
    const { messageText, messageColor } = message;
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormState({ ...formState, [name]: value });
      setMessage({ messageText: "", messageColor: "" });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (passwordOne !== passwordTwo || passwordOne === "") {
        setMessage({ messageText: "Passwords must match.", messageColor: "red" });
      } else {
        const changedPassword = await auth.resetPassword(passwordOne, token);
        if (changedPassword) {
            history.push("/login");
        } else {
          setMessage({ messageText: "The link you used from your email is no longer valid. Please request a new link.", messageColor: "red" });
          setForgetPswdLink(true);
        }
      }
    }

    return (
        <div className="auth-card" >
            <form onSubmit={handleSubmit}>
                <div className="auth-form-header">
                    <img className="form-logo" src={logo} alt="condor logo"/>
                    <span>Reset Password</span>
                </div>
                <input className="auth-input" type="password" name="passwordOne" placeholder="password" onChange={handleChange}/>
                <input className="auth-input" type="password" name="passwordTwo" placeholder="confirm password" onChange={handleChange}/>
                <button className="auth-submit">Login</button>
            </form>
            <div className="auth-form-footer">
                <Link className="cancel-form" to="/login">Cancel</Link>
            </div>
            
            {messageText && (
                <p className={messageColor}>{messageText}</p>
            )}

            {forgetPswdLink && <div className="center" ><Link id="get-password-form" to="/password">Forgot Password?</Link></div>}
        </div>
    )
}

export default PasswordResetForm;

