import React, { useState, useEffect } from "react";
import SystemTable from "./SystemTable";
import "../../styles/alarm.css";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { getSystemsBySiteId, getAllSystems } from "../../utils/apis";

function System() {

  const [systems, setSystems] = useState([]);
  const [siteId, setSiteId] = useState(-1);

  const history = useHistory();
  const { siteId: paramsSiteId } = useParams();

  useEffect(()=> { 
    getSystemData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const getSystemData = async () => {
    let systems;
    if (paramsSiteId !== siteId) {
      if (paramsSiteId) {
        systems = await getSystemsBySiteId(paramsSiteId);
      } else {
        systems = await getAllSystems();
      }

      if (systems && systems.length > 0) {
        systems = systems.map(sys => {
          if (!sys.system_name) {
            sys.system_name = sys.ip_address;
          }
          return sys;
        });
      }
      setSystems(systems);
      setSiteId(paramsSiteId);
    }
  }

  const openNewSystemForm = () => {
    history.push("/systems/detail/new");
  };

  return (
    <div>
      <SystemTable
        systems={systems}
        openNewSystemForm={openNewSystemForm}
      />
    </div>
  );
}

export default withRouter(System);
