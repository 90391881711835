import React from "react";
import "../../styles/alarmTable.css";
import { Link } from "react-router-dom";
import "../../styles/site.css";
import MainHeader from "../MainHeader";

function SystemTable(props) {
  function getHeartClasses(heartbeat) {
    switch (heartbeat) {
      case 0:
        return "fas fa-heart-broken lost-heartbeat";
      case 1:
        return "fas fa-heartbeat active-heartbeat";
      default:
        return "fas fa-heart-broken no-heartbeat";
    }
  }
  return (
    <div className="main">
      <MainHeader
        componentName="Systems"
        openAddItemForm={() => props.openNewSystemForm()}
      />
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>System</th>
              <th>Site</th>
              <th>Manufacturer</th>
              <th>Model</th> 
              <th></th>
              <th></th>
            </tr>
          </thead>
        
          <tbody>
            {props.systems &&
              props.systems.map((system) => {
                return (
                  <tr key={system.id}>
                    <td>
                      {
                        <Link to={`/systems/detail/${system.id}`}>
                          {" "}
                          {system.system_name || "unnamed"}{" "}
                        </Link>
                      }
                    </td>
                    <td>{system.site_name}</td>
                    <td>{system.manufacturer}</td>
                    <td>{system.model}</td>
                    <td>
                      {
                        <a href={system.system_hyperlink + "/?csj=" + window.sessionStorage.getItem('CSJ5410')} target="_blank" rel="noopener noreferrer">
                          <i className="fas fa-link"></i>
                        </a>
                      }
                    </td>
                    <td>
                      <i className={getHeartClasses(system.heartbeat_active)}></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SystemTable;


