import React, { useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import auth from "../utils/auth";
import logo from "../images/logo.png";
import "../styles/topNav.css";

function TopNavbar() {

  const [accountOpen, setAccountOpen] = useState(false);

  const [admin, setAdmin] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const userCompany = parseInt(
      auth.user.company_ids.replace("[", "").replace("]", "").split(", ")
    );

    // if current user is a condor admin
    if (userCompany === 0) {
      setAdmin(true);
    }
  }, []); 

  const toggleAcctNav = () => {
    setAccountOpen(!accountOpen);
  };

  const closeAcctNav = () => {
    setAccountOpen(false);
  };

  const showAccountNav = () => {
    return (
      <div className="overlay-div" onClick={closeAcctNav}>
        {/* if click in account-nav-div, it will stop at account-nav-div and not reach the click in .overlay-div b/c of stopPropagation */}
        <div id="account-nav-div" onClick={(e) => e.stopPropagation()}>
          <ul id="account-nav">
            {admin && (
              <li id="user-request-nav" onClick={handleNav}>
                Request New User
              </li>
            )}
            <li id="notifications-nav" onClick={handleNav}>
              Notifications
            </li>
            <li id="ticket-system-nav" onClick={handleNav}>
              Ticket System
            </li>
            <hr />
            <li id="logout-nav" onClick={handleNav}>
              Sign Out
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const handleNav = async (e) => {
    switch (e.target.id) {
      case "user-request-nav":
        history.push("/user/form");
        break;
      case "notifications-nav":
        history.push("/notifications");
        break;
      case "ticket-system-nav":
        history.push("/tickets");
        break;
      case "logout-nav":
      case "logout":
        await auth.logout("logout button");
        history.push("/login");
        break;
      default:
      //
    }
    setAccountOpen(false);
  };

  return (
    <nav id="top-nav">
      <ul>
        <Link to="/">
          <img id="nav-logo" src={logo} alt="logo"/>
          <span id="nav-brand-name">Condor Monitor</span>
        </Link>

        <div id="account-icon-div" onClick={toggleAcctNav}>
          <i id="account-icon" className="fas fa-sort-down"></i>
        </div>
      </ul>
      {accountOpen && showAccountNav()}
    </nav>
  );
}

export default withRouter(TopNavbar);
