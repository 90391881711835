import React, { useState } from "react";
import "../../styles/alarmDetail.css";
import MainHeader from "../MainHeader";
import "../../styles/site.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { deleteSystem } from "../../utils/apis";

function SystemDetailCards(props) {

  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [formState, setFormState] = useState({
    systemToDelete: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });
  
  const { systemToDelete } = formState;
  const { messageText, messageColor } = message;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const openDeleteItemForm = async () => {
    setOpenDeleteForm(true);
  };

  const closeDeleteItemForm = () => {
    setOpenDeleteForm(false);
  };

  const handleSubmitDeleteForm = async (event) => {
    event.preventDefault();
    // confirm typed system name correctly
    if (systemToDelete.trim() === (props.system.systemName).trim()) {

      const deletedSystem = await deleteSystem(props.system.id);

      if (deletedSystem) {
        props.history.push("/systems");
      } else {
        setMessage({ messageText: "There was an error deleting this system. Please try again.", messageColor: "red" });
        return;
      }
    } else {
      setMessage({ messageText: "You did not enter the system name correctly. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader
        componentName="System Details"
        openUpdateItemForm={() => props.openDetailForm()}
        openDeleteItemForm={() => openDeleteItemForm()}
      />
      {openDeleteForm && (
        <div className="overlay-div" onClick={(e) => closeDeleteItemForm()}>
          <div className="main main-detail delete-item-div" onClick={(e) => e.stopPropagation()}>
            <form id="site-form" onSubmit={handleSubmitDeleteForm}>
              <div className="alarm-detail-section">
                <p className="heading">
                  <span className="red">ARE YOU SURE?</span>
                </p>

                <p className="bold">
                  {" "}
                  You are about to delete the following system and its
                  associated alarms.
                </p>
                <p className="item-to-delete-description">
                  {" "}
                  SYSTEM ID: {props.system.ipAddress}
                </p>
                <p className="item-to-delete-description">
                  {" "}
                  SYSTEM NAME: {props.system.systemName}
                </p>
                <p>
                  <span className="detail">
                    Type{" "}
                    <span className="blue">{props.system.systemName}</span> to
                    confirm:
                  </span>
                  <input
                    type="text"
                    name="systemToDelete"
                    value={systemToDelete}
                    onChange={handleChange}
                  />
                </p>
                <button className="details-submit">DELETE THIS SYSTEM</button>
                <div
                  className="details-form-footer"
                  onClick={() => closeDeleteItemForm()}
                >
                  Cancel
                </div>
                {messageText && (
                  <p className={messageColor}>{messageText}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      <div>
        <div className="alarm-detail-section">
          <p className="heading">SYSTEM</p>
          <div className="detail-item">
            <p className="detail">ID: </p>
            <p>System {props.system.systemEquipId}</p>
          </div>
          <div className="detail-item">
            <p className="detail">IP Address: </p>
            <p>{props.system.ipAddress}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Name: </p>
            <p>{props.system.systemName}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Manufacturer:</p>
            <p>{props.system.manufacturer}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Model:</p>
            <p>{props.system.model}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Port:</p>
            <p>{props.system.port}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Last Heartbeat: </p>
            <p>{props.system.heartbeat}</p>
          </div>
          <div>
            <p id="location-notes-label" className="detail">
              Location Notes:
            </p>
            <p className="wrapping-text">
              {props.system.locationNotes}
            </p>
          </div>
        </div>

        <div className="alarm-detail-section">
          <p className="heading">SITE</p>
          <div className="detail-item">
            <p className="detail">ID:</p>
            <p>{props.system.siteMacId}</p>
          </div>
          <div className="detail-item">
            <p className="detail">IP Address:</p>
            <p>{props.system.siteIp}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Name:</p>
            <p>{props.system.siteName}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Address:</p>
            <p>{props.system.siteAddress}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Coordinates:</p>
            {props.system.siteLatitude && props.system.siteLongitude && (
            <p>
              {props.system.siteLatitude},{" "}
              {props.system.siteLongitude}{" "}
              <Link to={`/map`}>
                {" "}
                <i className="fas fa-map-marker-alt"></i>See map
              </Link>
            </p>
            )}
          </div>
          <div className="detail-item">
            <p className="detail">Last Heartbeat:</p>
            <p>{props.system.siteHeartbeat}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Contact:</p>
            <p>{props.system.siteContactName}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Email:</p>
            <p>{props.system.siteContactEmail}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Phone:</p>
            <p>{props.system.siteContactPhone}</p>
          </div>
          <div className="detail-item">
            <p id="contact-notes-label" className="detail">
              Contact Notes:
            </p>
            <p className="wrapping-text">
              {props.system.siteContactNotes}
            </p>
          </div>
          <div className="detail-item last-detail-item">
            <p id="access-notes-label" className="detail">
              Access Notes:
            </p>
            <p className="wrapping-text">
              {props.system.siteAccessNotes}
            </p>
          </div>
        </div>
        <div className="alarm-detail-section">
          <p className="heading">LINKS</p>
          <p>
            <a
              href={
                props.system.systemHyperlink +
                "/?csj=" +
                window.sessionStorage.getItem("CSJ5410")
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-link"></i>Remote Access
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SystemDetailCards);