import React, { useState } from "react";
import { postFileData } from "../../utils/apis";

function NewFileUpload(props) {

  const [formState, setFormState] = useState({
    description: "",
    selectedFile: null,
    fileSize: null,
    fileName: "No File Chosen"
  });
  const [formSubmitStatus, setFormSubmitStatus] = useState(false);
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { description, selectedFile, fileSize, fileName } = formState;
  const { messageText, messageColor } = message;

  //sets the state of selectedFile by accessing the FileListobject provided by the DOM containing a list of File objects representing the files selected by the user
  const handleSelectedFile = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setFormState({
        ...formState, 
        selectedFile: event.target.files[0],
        fileSize: event.target.files[0].size,
        fileName: event.target.files[0].name,
      });
      setMessage({ messageText: "", messageColor: "" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setMessage({ messageText: "You must choose a file.", messageColor: "red" });
      return;
    }
  
    let fileStorageRemaining = props.fileStorageRemaining;

    if (fileStorageRemaining === null) {
      fileStorageRemaining = props.fileStoragePlan;
    }

    // from bytes to megabytes
    const fileMegabytes = (fileSize)/1000000;
    const fileSizeRounded = Math.round(fileMegabytes * 1000)/1000;

    if (fileStorageRemaining < fileSizeRounded) {
      setMessage({ messageText: "This file exceeds your storage allowance. Please contact condormonitorinfo@gmail.com to select a different storage plan.", messageColor: "red" });
      return;
    } else {
      setMessage({messageText: "Your file is uploading...", messageColor: "green"});
      setFormSubmitStatus(true);
    }

    // creates a new instance of the FormData object
    const data = new FormData(event.target);

    // appends a set of key/value pairs to the above FormData object
    data.append("file", selectedFile);
    data.append("siteId", props.siteId);
    data.append("desc", description);

    const fileData = await postFileData(data);

    if (fileData) {
      props.closeForm();
    } else {
      setMessage({ messageText: "There was an error uploading this file. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div>
      <form onSubmit={handleUpload}>
        <p><span className="detail">Storage Plan:</span><span>{props.fileStoragePlan} MB</span></p>
        <p><span className="detail">Storage Remaining:</span><span>{props.fileStorageRemaining || props.fileStoragePlan} MB</span></p>
        <label className="detail file-desc-label" htmlFor="description">Description:</label>
        <textarea
          type="text"
          className="site-form-textarea file-desc-textarea"
          name="description"
          maxLength="250"
          onChange={handleChange}
          placeholder="Enter a description"
        />

        <p id="select-file-wrapper">
          <label id="select-file-label" htmlFor="select-file-input">Choose File</label>    
          {/* File type - returns a list of selected File objects as a FileList  */}
          <input
            type="file"
            id="select-file-input"
            onChange={handleSelectedFile}
          />
          <span id="selected-file-value">{fileName}</span>
        </p>

        {!formSubmitStatus && (
        <button type="submit" className="details-submit file-desc-submit">
          Upload
        </button>)}
        {formSubmitStatus && (
        <button type="submit" className="details-submit file-desc-submit" disabled>
          Upload
        </button>)}
        <div className="details-form-footer" onClick={props.closeForm}>Cancel</div>
      </form>
      {messageText && (
        <p className={messageColor}>{messageText}</p>
      )}
    </div>
  );
}

export default NewFileUpload;