import React from "react";
import "../styles/mainHeader.css";

function MainHeader(props) {
  let icon = "";

  if (props.openUpdateItemForm && props.openDeleteItemForm) {
    icon = <span><i className="fas fa-pen" onClick={props.openUpdateItemForm}></i> <i className="far fa-trash-alt" onClick={props.openDeleteItemForm}></i></span>;
  } else if (props.openUpdateItemForm) {
    icon = <i className="fas fa-pen" onClick={props.openUpdateItemForm}></i>;
  } else if (props.openAddItemForm) {
    icon = <i className="fas fa-plus" onClick={props.openAddItemForm}></i>;
  } else if (props.openDeleteItemForm) {
    icon = <i className="far fa-trash-alt" onClick={props.openDeleteItemForm}></i>;
  }

  return (
    <div>
      <header className="header">
        {" "}
        {props.componentName}
        {icon}
      </header>
      <div className="header-right">{props.children}</div>
    </div>
  );
};

export default MainHeader;
