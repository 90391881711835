const dayjs = require("dayjs");
const localizedFormat = require("dayjs/plugin/localizedFormat");
const utc = require("dayjs/plugin/utc");
dayjs.extend(localizedFormat);
dayjs.extend(utc);

const modifyUTCToLocalTime = (UTCTime) => {
  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "test"
  ) {
    return dayjs(UTCTime).format("L LT");
  } else {
    // new Date() returns time as an object containing the number of milliseconds elapsed since the start of 1970 in UTC
    const dateObj = new Date(UTCTime);

    //new Date().getTimezoneOffset() returns diff b/t UTC and local time in minutes
    // *6000 to covert it to milliseconds
    const dateMinutes = dateObj + new Date().getTimezoneOffset() * 6000;

    let localTime = dayjs(dateMinutes).format("L LT");

    if (localTime === "Invalid Date") {
      localTime = "";
    }

    return localTime;
  }
};

module.exports = {
  modifyUTCToLocalTime,
};
