import axios from "axios";

class Auth {
  constructor() {
    this.authenticated = false;
    this.user = null;
  }

  async signup(firstName, lastName, email, password, token) {
    try {
      const response = await axios({
        //url: "/signup",
        url: "/api/signup",
        method: "POST",
        data: {
          email,
          password,
          firstName,
          lastName,
          token,
        },
      });
      this.authenticated = true;
      this.user = response.data;
      sessionStorage.setItem("CSJ5410", response.data.session);
      return true;
    } catch (err) {
      return err.response.status;
    }
  }


  // ping server to confirm logged in and didn't close tab
  async pingServer() {
    // sends initial ping
    axios({
      url: "/api/ping/" + sessionStorage.getItem("CSJ5410"),
      method: "POST"
    });
    // sends ping every subsequent hour
    setInterval(()=>{
      axios({
        url: "/api/ping/" + sessionStorage.getItem("CSJ5410"),
        method: "POST"
      });

    }, 3600000)
  }

  async login(email, password) {
    try {
      const response = await axios({
        url: "/api/signin",
        method: "POST",
        data: {
          email,
          password,
        },
      });
      this.authenticated = true;

      sessionStorage.setItem("CSJ5410", response.data.session);
      this.pingServer();

      this.user = response.data;
      return true;
    } catch (err) {
      return false;
    }
  }
  async verifyUser() {
    try {
      const response = await axios({
        url: "/api/users/verify/" + sessionStorage.getItem("CSJ5410"),
        method: "GET",
      });

      if (response.data) {
        this.authenticated = true;
        this.user = response.data;
      }
      return this.user;
    } catch (err) {
      return false;
    }
  }

  async logout(source) {

    try {
      await axios({
        url: "/api/logout/" + sessionStorage.getItem("CSJ5410"),
        method: "POST",
        data: {
          source
        },
      });
      this.authenticated = false;
      sessionStorage.removeItem("CSJ5410");
      return true;
    } catch (err) {
      return false;
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  getUser() {
    return this.user;
  }

  requestPasswordReset(email) {
    try {
      axios({
        url: "/api/password",
        method: "POST",
        data: {
          email,
        },
      });
    } catch (err) {
      return false;
    }
  }

  async resetPassword(password, token) {
    try {
      const response = await axios({
        url: `/api/password/${token}`,
        method: "POST",
        data: { password },
      });

      if (response.status === 200) {
        return true;
      } else {
        // either the link expired or user not found
        return false;
      }
    } catch (err) {
      return false;
    }
  }
}

export default new Auth();
