import React, { useState, useEffect } from "react";
import "../../styles/site.css";
import "../../styles/alarmDetail.css";
import "../../styles/advancedAlarmSearch.css";
import { modifyUTCToLocalTime } from "../../utils/helpers";
import { getFilteredAlarms, getAllSites, getSystemsBySelectedSite } from "../../utils/apis";

function AdvancedAlarmSearch(props) {

  const [sites, setSites] = useState(null);
  const [systems, setSystems] = useState(null);
  const [siteName, setSiteName] = useState("");
  const [systemName, setSystemName] = useState("");
  const [systemsClass, setSystemsClass] = useState("disabled-system");

  const [formState, setFormState] = useState({
    fromDate: "",
    toDate: "",
    activeOnly: false,
    site: "",
    system: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { fromDate, toDate, activeOnly, site, system } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      let sites = await getAllSites();
      if (sites && sites.length > 0) {
        sites = sites.map(site=> {
          if (!site.site_name) {
            site.site_name = site.mac_id;
          }
          return site;
        });
      }
      setSites(sites);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDateValidity = (date) => {
    let days;
    const monthInputted = date.slice(0,2);
    const daysInputted = date.slice(3,5);
    const yearInputted  = date.slice(6,10);

    switch (monthInputted) {
      case "01":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in January. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "02":
        if (yearInputted === "2020" || yearInputted === "2024" || yearInputted === "2028" || yearInputted === "2032" || yearInputted === "2036" || yearInputted ===  "2040" || yearInputted === "2044" || yearInputted === "2048") {
          days = 29;
          if (daysInputted < 0 || daysInputted > days ) {
            setMessage({ messageText: "There are 29 days in February " + yearInputted + ". Please enter a valid date.", messageColor: "red" });
            return false;
          }
        } else {
          days = 28;
          if (daysInputted < 0 || daysInputted > days ) {
            setMessage({ messageText: "There are 28 days in February " + yearInputted + ". Please enter a valid date.", messageColor: "red" });
            return false;
          }
        }
        return true;
      case "03":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in March. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "04":
        days = 30;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 30 days in April. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "05":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in May. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "06":
        days = 30;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 30 days in June. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "07":
        days =  31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in July. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "08":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in August. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "09":
        days = 30;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 30 days in September. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "10":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in October. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "11":
        days = 30;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 30 days in November. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      case "12":
        days = 31;
        if (daysInputted < 0 || daysInputted > days ) {
          setMessage({ messageText: "There are 31 days in December. Please enter a valid date.", messageColor: "red" });
          return false;
        }
        return true;
      default: 
      setMessage({ messageText: "Please enter a valid date.", messageColor: "red" });
        return false;
    }
  }

  const getEventText = (event) => {
    const index = event.nativeEvent.target.selectedIndex;
    return event.nativeEvent.target[index].text;
  }

  const handleInputChange = async (event) => {
    const { name, value } = event.target;

    let hasOnlyNumberAndSlash;

    if (name === "fromDate" || name === "toDate") {
      // confirm value is a number or forward slash
      hasOnlyNumberAndSlash = value.match(/^[0-9/]+$/);
      if(!hasOnlyNumberAndSlash && value !== "") {
        setMessage({ messageText: "Please enter the to and from dates in the format mm/dd/yyyy", messageColor: "red" });
        return;
      } 
    } else if (name === "site") {
      const siteName = getEventText(event);
      if (value !== "0") {
        const systems = await getSystemsBySelectedSite(value);
        if (systems) {
          setSystems(systems);
          setSystemsClass("");
        } else {
          setSystems(null);
          setSystemsClass("disabled-system");       
        }
        setFormState({...formState, site: value});
        setSiteName(siteName);
        setMessage({ messageText: "", messageColor: "" });
      } else if (value === "0") {
        setSystems(null);
        setSystemsClass("disabled-system"); 
        setFormState({...formState, site: value});
        setSiteName(siteName);
        setMessage({ messageText: "", messageColor: "" });
      }
      return;
    } else if (name === "system") {
      const systemName = getEventText(event);
      setFormState({...formState, [name]: value});
      setSystemName(systemName);
      setMessage({ messageText: "", messageColor: "" });
      return;
    }
    setFormState({...formState, [name]: value});
    setMessage({ messageText: "", messageColor: "" });
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState({ ...formState, [name]: checked,});
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleFilterSubmit = async (event) => {
    event.preventDefault();

    let properFromDateFormat;
    let properToDateFormat;
    let fromDateObj;
    let toDateObj;
    let alarms;

    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      setMessage({ messageText: "To filter by date you must enter a to and a from date.", messageColor: "red" });
      return;
    } else if (fromDate !== "" && toDate !== "") {

      //check if date in format mm/dd/yyyy 
      properFromDateFormat = (fromDate).match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/);
      properToDateFormat = (toDate).match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/);

      const isValidFromDate = checkDateValidity(fromDate);
      const isValidToDate = checkDateValidity(toDate);

      if (!isValidFromDate || !isValidToDate) {
        return;
      }

      if( (!properFromDateFormat || !properToDateFormat)) {
        setMessage({ messageText: "Please enter the to and from dates in the format mm/dd/yyyy", messageColor: "red" });
        return;
      } 

      // new Date() returns datetime as an object containing the number of milliseconds elapsed since the start of 1970 in UTC
      fromDateObj = new Date(fromDate);

      toDateObj = (new Date(toDate)).setHours(23, 59, 59);

      // need to wrap it in new Date again to get it in the correct format
      toDateObj = new Date(toDateObj);
  
      alarms = await getFilteredAlarms(fromDateObj, toDateObj, activeOnly, site, system);
    } else {
      alarms = await getFilteredAlarms("", "", activeOnly, site, system);
    }

    getFilterString(fromDateObj, toDateObj);

    props.filterAlarms(alarms, true);

    props.closeAdvancedSearchForm();
  }

  const getFilterString = (fromDateObj, toDateObj) => {
    if (fromDateObj && toDateObj) {
      fromDateObj = modifyUTCToLocalTime(fromDateObj).slice(0,10);
      toDateObj = modifyUTCToLocalTime(toDateObj).slice(0,10);
    } else {
      fromDateObj = "";
      toDateObj = "";
    }

    const filters = {
      fromDate: fromDateObj,
      toDate: toDateObj,
      activeOnly: activeOnly,
      site: siteName,
      system: systemName
    }
    props.setFilterString(filters);
  }

  return (
    <div className="overlay-div" onClick={props.closeAdvancedSearchForm}>
      {/* if click in alarm-advanced-search-div, it will stop at alarm-advanced-search-div and not reach the click in .overlay-div b/c of stopPropagation */}
      <form id="advanced-alarm-search-form" className="alarm-detail-section" onClick={(e) => e.stopPropagation()} onSubmit={handleFilterSubmit}>
          <p className="heading"> ADVANCED SEARCH </p>

          <p>
            <span className="detail">From: </span>
            <input id="alarm-date-from-input" type="text" placeholder="mm/dd/yyyy" name="fromDate" value={fromDate}
            onChange={handleInputChange}/>

            <span className="detail">To: </span>
            <input id="alarm-date-to-input" type="text" placeholder="mm/dd/yyyy" name="toDate" value={toDate}
            onChange={handleInputChange}/>
          </p>

          <p>
            <input
              className="checkbox"
              name="activeOnly"
              type="checkbox"
              checked={activeOnly}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="none">Include Only Active Alarms</label>
          </p>

          <p>
            <span className="detail">
              Sites:{" "}
            </span>
            <select
              name="site"
              value={site}
              onChange={handleInputChange}
            >

              {sites && 
                <option value="0">All Sites</option>
              }

              {sites && 
                sites.map((site, i) => (
                  <option key={i} value={site.id}>
                    {site.site_name}
                  </option>
                ))
              }

              {!sites && 
                <option value="">
                  {"No Sites to Select"}
                </option>
              }
            </select>
          </p>

          <p className={systemsClass}>
            <span className="detail">
              Systems:{" "}
            </span>
            <select
              name="system"
              value={system}
              onChange={handleInputChange}
            >
              {systems && 
                <option value="">All Systems</option>
              }
              
              {systems && 
                systems.map((system, i) => (
                  <option key={i} value={system.id}>
                    {system.system_name}
                  </option>
                ))
              }

              {!systems && 
                <option value="">
                  {"No Systems to Select"}
                </option>
              }
            </select>
          </p>

          <button className="details-submit">Submit</button>
          <div
            className="details-form-footer"
            onClick={() => props.closeAdvancedSearchForm()}
          >
            Cancel
          </div>
          {messageText && (
          <p className={messageColor}>{messageText}</p>
        )}
      </form>
    </div>
  );
}

export default AdvancedAlarmSearch;