import React from 'react';
import "../../styles/alarmTable.css";
import { Link } from "react-router-dom";

function AlarmTable (props) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Site</th>
                    <th>System</th>
                    <th>Event</th> 
                    <th>Level</th>
                    <th>Status</th>
                    <th></th>{/* Remote */}
                </tr>
            </thead>
        
            <tbody>
                {props.alarms && props.alarms.map(alarm => {
                    return (
                        <tr key={alarm.id}>
                            <td>{<Link to={`/alarms/detail/${alarm.id}`} > {alarm.start_time} </Link>}</td>   
                            <td>{alarm.site_name}</td>
                            <td>{alarm.system_name}</td>
                            <td>{alarm.title}</td>
                            <td>{alarm.level}</td>
                            <td>{alarm.status}</td>
                            <td>{<a href={alarm.system_hyperlink + "/?csj=" + window.sessionStorage.getItem('CSJ5410')} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a>}</td>
                        </tr>
                    )
                })}  
            </tbody>
        </table>
    )
};

export default AlarmTable;

