import React, { useState, useEffect } from "react";
import { getFilesBySiteId, deleteFileByFileId } from "../../utils/apis";

function FileUpload(props) {

  const [files, setFiles] = useState(null);
  const [fileStorageUsed, setFileStorageUsed] = useState(0);

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      if (props.siteId) {
        let files = await getFilesBySiteId(props.siteId);
        if (files) {
          props.updateFileStorageInfo(files[0].file_storage_used)
          files = files.map((file) => {
            const fileNameArr = (file.file_name).split("_");
            const fileNameArrClone = [...fileNameArr];
            fileNameArrClone.pop();
            file.file_name_without_token = fileNameArrClone.join("_");
            return file;
          });
          setFiles(files);
          setFileStorageUsed(files[0].file_storage_used);
        } 
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFile = async (fileId, fileName, fileSize) => {

    const deletedFile = await deleteFileByFileId(fileId, fileName);

    if (deletedFile) {
      props.updateFileStorageInfo(fileStorageUsed - fileSize);
      setFiles(files.filter(file => file.id !== fileId));
      setFileStorageUsed(fileStorageUsed - fileSize);
    } 
  }

  return (
    <div>
      <table id="file-table">
        <colgroup>
          <col id="file-table-name-col"></col>
          <col id="file-table-desc-col"></col>
          <col id="file-table-size-col"></col>
          <col id="file-table-edit-icon-col"></col>
          <col id="file-table-delete-icon-col"></col>
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Size (MB)</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files && files.map( (file,i) => (
            <tr key={i}>
              <td><a  id="file-table-name" href={file.link} target="_blank" rel="noopener noreferrer">
                  {file.file_name_without_token}
                </a></td>
              <td><p id="file-table-desc">{file.description}</p></td>
              <td><p id="file-table-size">{file.size}</p></td>
              <td>
              <i id="edit-file-icon" onClick={() => props.openEditFileDescForm(file.id, file.file_name_without_token, file.size, file.description)} className="fas fa-pen"></i>
              </td>
              <td>
              <i id="delete-file-icon" onClick={() => deleteFile(file.id, file.file_name, file.size)} className="far fa-trash-alt"></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FileUpload;