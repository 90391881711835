import React from "react";
import "../../styles/alarmTable.css";
import { Link } from "react-router-dom";
import "../../styles/site.css";
import MainHeader from "../MainHeader";

function SiteTable(props) {
  function getHeartClasses(heartbeat) {
    switch (heartbeat) {
      case 0:
        return "fas fa-heart-broken lost-heartbeat";
      case 1:
        return "fas fa-heartbeat active-heartbeat";
      default:
        return "fas fa-heart-broken no-heartbeat";
    }
  }
  return (
    <div className="main">
      <MainHeader
        componentName="Sites"
        openAddItemForm={() => props.openNewSiteForm()}
      />
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Site</th>
              <th>Systems</th>
              <th>Address</th>
              <th>Contact</th> 
              <th>Email</th>
              <th>Phone</th>
              <th></th>
              {/* Remote */}
            </tr>
          </thead>

          <tbody>
            {props.sites &&
              props.sites.map((site) => {
                return (
                  <tr key={site.id}>
                    <td>
                      {
                        <Link to={`/sites/detail/${site.id}`}>
                          {" "}
                          {site.site_name || "unnamed"}{" "}
                        </Link>
                      }
                    </td>
                    <td>
                      <Link to={`/systems/site/${site.id}`}>
                        {site.num_of_systems}
                      </Link>
                    </td>
                    <td>{site.site_address}</td>
                    <td>{site.contact_name}</td>
                    <td>{site.email}</td>
                    <td>{site.phone}</td>
                    <td>
                      <i className={getHeartClasses(site.heartbeat_active)}></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SiteTable;


