import React, { useState, useEffect } from "react";
import "../../styles/alarmDetail.css";
import "../../styles/site.css";
import "../../styles/notification.css";
import { updateFileDescription } from "../../utils/apis";

function FileDescriptionEdit(props) {

  const [description, setDescription] = useState("")
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { messageText, messageColor } = message;

  useEffect(() => {
    if (props.fileDesc) {
      setDescription(props.fileDesc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const updatedDesc = await updateFileDescription(props.fileId, description);

    if (updatedDesc) {
      props.closeForm();
    } else {
      setMessage({ messageText: "There was an error deleting this file. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <p id="file-desc-name"><span className="detail">Name:</span><span id="file-desc-name-value">{props.fileName}</span></p>
          <p><span className="detail">Size:</span><span>{props.fileSize} MB</span></p>
          <label className="detail file-desc-label" htmlFor="description">Description:</label>
          <textarea
            className="site-form-textarea file-desc-textarea"
            type="text"
            value={description}
            name="description"
            maxLength="250"
            onChange={handleChange}
            placeholder="Enter a description"
          />
        </div>
        <button className="details-submit file-desc-submit" type="submit">
          Submit
        </button>
        <div className="details-form-footer" onClick={props.closeForm}>Cancel</div>
        {messageText && (
          <p className={messageColor}>{messageText}</p>
        )}
      </form>
    </div>
  );
}

export default FileDescriptionEdit;