import React, { useState } from "react";
import auth from "../../utils/auth";
import logo from "../../images/logo.png";
import "../../styles/auth.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { condorCustomerServiceEmail } from "../../constants.js";

function SignupForm() {

  const history = useHistory();
  const { token } = useParams();

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    passwordOne: "",
    passwordTwo: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { firstName, lastName, email, passwordOne, passwordTwo } = formState;
  const { messageText, messageColor } = message;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      passwordTwo === "" ||
      firstName === "" ||
      lastName === "" ||
      email === "";

    if (isInvalid) {
      if (passwordOne !== passwordTwo) {
        setMessage({
          messageText: "Both passwords must match.",
          messageColor: "red",
        });
        return;
      } else {
        setMessage({
          messageText: "Please complete all fields.",
          messageColor: "red",
        });
        return;
      }
    }
    try {
      const isSignedIn = await auth.signup(
        firstName,
        lastName,
        email,
        passwordOne,
        token
      );

      switch (isSignedIn) {
        case true:
          if (isSignedIn === true) {
            history.push("/sites"); // change back to dashboard later *************************
          }
          break;
        case 404:
        case 400:
          setMessage({
            messageText: `This sign up invitation has expired or you entered the wrong email. Please try again or contact customer service at ${condorCustomerServiceEmail}.`,
            messageColor: "red",
          });
          break;
        default:
          setMessage({
            messageText: `There was an error during sign up. Please try again or contact customer service at ${condorCustomerServiceEmail}.`,
            messageColor: "red",
          });
      }
    } catch (err) {
      setMessage({
        messageText: `There was an error during sign up. Please try again or contact customer service at ${condorCustomerServiceEmail}.`,
        messageColor: "red",
      });
    }
  };

  return (
    <div className="auth-card">
      <form onSubmit={handleSubmit}>
        <div className="auth-form-header">
          <img className="form-logo" src={logo} alt="condor logo" />
          <span>Sign Up</span>
        </div>
        <input
          className="auth-input"
          type="text"
          name="firstName"
          placeholder="First Name *"
          onChange={handleChange}
        />
        <input
          className="auth-input"
          type="text"
          name="lastName"
          placeholder="Last Name *"
          onChange={handleChange}
        />
        <input
          className="auth-input"
          type="text"
          name="email"
          placeholder="Email *"
          onChange={handleChange}
        />
        <input
          className="auth-input"
          type="password"
          name="passwordOne"
          placeholder="Password *"
          onChange={handleChange}
        />
        <input
          className="auth-input"
          type="password"
          name="passwordTwo"
          placeholder="Retype Password *"
          onChange={handleChange}
        />
        <button className="auth-submit">Signup</button>
      </form>
      <div className="auth-form-footer">
        <Link className="cancel-form" to="/login">
          Cancel
        </Link>
      </div>
      {messageText && (
        <p className={messageColor}>{messageText}</p>
      )}
    </div>
  );
}

export default SignupForm;
