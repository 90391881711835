import axios from "axios";

export const getNotifications = async () => {
  try {
    const { data: notifications } = await axios({
      url: `/api/notifications`,
      method: "GET",
    });

    if (!notifications || notifications.length < 1) {
      return false;
    } else {
      return notifications[0];
    }
  } catch (err) {
    return false;
  }
};

export const getTicketEmail = async () => {
  try {
    const email = (await axios({
      url: `/api/companies/tickets/email`,
      method: "GET",
    })).data.ticket_system_email;

    if (!email) {
      return false;
    } else {
      return email;
    }
  } catch (err) {
    return false;
  }
};

export const getAllCompanies = async () => {
  try {
    const { data: companyData } = await axios({
      url: "/api/companies",
      method: "GET",
    });

    if (companyData && companyData.length > 0) {
      return companyData;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
  
export const getUserByEmail = async (email) => {
  try {
    const { data: user } = await axios({
      url: "/api/users/email/" + email,
      method: "GET",
    });

    if (!user || user.length < 1) {
      return false;
    } else {
      return user;
    }
  } catch (err) {
    return false;
  }
};

export const getAllSites = async () => {
  try {
    const siteData = await axios({
      url: "/api/sites",
      method: "GET",
    });

    if (siteData && siteData.data && siteData.data.length > 0) {
      return siteData.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getSystemDetails = async (systemId) => {
  try {
    const systemDetails = await axios({
      url: `/api/systems/${systemId}`,
      method: "GET",
    });
    if (systemDetails && systemDetails.data && systemDetails.data.id) {
      return systemDetails.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const getSiteDetails = async (siteId) => {
  try {
    const siteDetails = await axios({
      url: `/api/sites/${siteId}`,
      method: "GET",
    });
    if (siteDetails && siteDetails.data) {
      return siteDetails.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const getSystemsBySiteId = async (siteId) => {
  try {
    const systemData = await axios({
      url: `/api/systems/site/${siteId}`,
      method: "GET",
    });

    if (systemData && systemData.data && systemData.data.length > 0) {
      return systemData.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getAllSystems = async () => {
  try {
    const systemData = await axios({
      url: "/api/systems",
      method: "GET",
    });
    if (systemData && systemData.data && systemData.data.length > 0) {
      return systemData.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getUnassignedSites = async () => {
  try {
    const unassignedSites = await axios({
      url: `/api/sites/new`,
      method: "GET",
    });
    if (
      unassignedSites &&
      unassignedSites.data &&
      unassignedSites.data.length > 0
    ) {
      return unassignedSites.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getFilesBySiteId = async (siteId) => {
  try {
    const files = await axios({
      url: "/api/files/" + siteId,
      method: "GET",
    });
    if (
      files && files.data && files.data.data && files.data.data.length > 0
    ) {
      return files.data.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const getAlarmDetails = async (alarmId) => {
  try {
    const alarmDetailsData = await axios({
      url: `/api/alarms/${alarmId}`,
      method: "GET",
    });
    if (alarmDetailsData && alarmDetailsData.data) {
      return alarmDetailsData.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const getAllAlarms = async () => {
  try {
    const alarmData = await axios({
      url: "/api/alarms",
      method: "GET",
    });

    if (!alarmData || !alarmData.data || alarmData.data.length < 1) {
      return false;
    } else {
      return alarmData.data;
    }
  } catch (err) {
    return false;
  }
};

export const getAlarmsBySiteId = async (siteId) => {
  try {
    const alarmData = await axios({
      url: `/api/alarms/site/${siteId}`,
      method: "GET",
    });

    if (!alarmData || !alarmData.data || alarmData.data.length < 1) {
      return false;
    } else {
      return alarmData.data;
    }
  } catch (err) {
    return false;
  }
};

export const getFilteredAlarms = async (fromDateObj, toDateObj, activeOnly, site, system) => {
  try {
    const alarmData = await axios({
      url: "/api/alarms/filter",
      method: "GET",
      params: {
        fromDateObj,
        toDateObj,
        activeOnly, 
        site,
        system
      }
    });

    if (!alarmData || !alarmData.data || alarmData.data.length < 1) {
      return false;
    } else {
      return alarmData.data;
    }
  } catch (err) {
    return false;
  }
};

export const getSystemsBySelectedSite = async (siteId) => {
  try {
     const systemData = await axios({
      url: `/api/systems/site/${siteId}`,
      method: "GET",
    });

    if (systemData && systemData.data && systemData.data.length > 0) {
      const systems = systemData.data.map(sys => {
        if (!sys.system_name) {
          sys.system_name = sys.ip_address;
        }
        return sys;
      });
      return systems;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const postUserInvite = async (data) => {
  try {
    const {data: inviteStatus} = await axios({
      url: "/api/users/invite",
      method: "POST",
      data
    });

    if (!inviteStatus || inviteStatus.length < 1) {
      return false;
    } else {
      return inviteStatus;
    }
  } catch (err) {
    return false;
  }
};

export const postFileData = async (data) => {
  try {
    const fileData = await axios
    .post("/api/files/upload", data);

    if (fileData) {
      return fileData;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const postAlarmNote = async (alarmId, note) => {
  try {
    // stores note
    await axios({
      url: `/api/alarms/note`,
      method: "POST",
      data: {
        alarmId,
        note
      },
    });

    //retrieves notes just stored with added note
    const updatedNotes = await axios({
      url: `/api/notes/alarm/${alarmId}`,
      method: "GET",
    });

    if (updatedNotes && updatedNotes.data && updatedNotes.data.length > 0) {
      return updatedNotes.data;
    } else {
      return false
    }
  } catch (err) {
    return false;
  }
};

export const updateNotifications = async (data) => {
  try {
    const updatedNotifications = await axios({
      url: "/api/notifications",
      method: "PUT",
      data
    });
    if (updatedNotifications.status === 204) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const updateTicketSystemEmail = async (ticketSystemEmail) => {
  try {
    const updatedCompany = await axios({
      url: "/api/companies/tickets/email",
      method: "PUT",
      data: {
        email: ticketSystemEmail
      },
    });
    if (updatedCompany.status === 204) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const updateSystem = async (method, data) => {
  try {
    const updatedSystem = await axios({
      url: "/api/systems",
      method: method,
      data
    });

    if (updatedSystem && updatedSystem.data) {
        return {data: updatedSystem.data.data};
    } else {
      return false;
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const error = err.response.data.split("-")[0];
      const errMessage = err.response.data.split("-")[1];
      if (error === "System unavailable") {
        return {err: errMessage}
      } else {
        return false;
      }
    }
  }
};

export const updateSite = async (method, data) => {
  try {
    const updatedSite = await axios({
      url: "/api/sites",
      method: method,
      data
    });

    if (updatedSite && updatedSite.data) {
        return {data: updatedSite.data};
    } else {
      return false;
    }
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.message === "This site id is not available."
    ) {
      return {err: err.response.data.message};
    } else {
      return false;
    }
  }
};

export const updateFileDescription = async (fileId, description) => {
  try {
    const updatedDesc = await axios({
      url: "/api/files",
      method: "PUT",
      data: {
        fileId,
        description
      },
    });
    if (updatedDesc) {
      return true;
    } else {
      return false;
    }
  } catch(err) {
    return false;
  }
}

export const deleteSystem = async (systemId) => {
  try {
    const systemData = await axios({
      url: "/api/systems/" + systemId,
      method: "DELETE",
    });  
    if (systemData && systemData.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const deleteSite = async (siteId) => {
  try {
    const siteData = await axios({
      url: "/api/sites/" + siteId,
      method: "DELETE",
    });  
    if (siteData && siteData.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export const deleteFileByFileId = async (fileId, fileName) => {
  try {
    const deletedFile = await axios({
      url: "/api/files",
      method: "DELETE",
      data: {
        fileId,
        fileName
      },
    });
    if (deletedFile && deletedFile.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

