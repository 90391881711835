import React, { useState, useEffect } from "react";
import "../../styles/alarmDetail.css";
import MainHeader from "../MainHeader";
import "../../styles/site.css";
import { withRouter } from "react-router";
import { updateSystem, getAllSites } from "../../utils/apis";

function SystemDetailForm(props) {

  const [id, setId] = useState("");
  const [newSystem, setNewSystem] = useState(false);
  const [sites, setSites] = useState(null);
  const [resetHeartbeatInfo, setResetHeartbeatInfo] = useState("");

  const [formState, setFormState] = useState({
    systemEquipId: "",
    systemName: "",
    manufacturer: "",
    model: "",
    resetHeartbeat: false,
    siteId: "",
    siteName: "",
    siteMacId: "",
    locationNotes: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { systemEquipId, systemName, manufacturer, model, resetHeartbeat, siteId, siteMacId, locationNotes } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {

      // get all sites and add result to thet set state below
      let allSites = await getAllSites();

      if (allSites && allSites.length > 0) {
        allSites = allSites.map(site=> {
          if (!site.site_name) {
            site.site_name = site.mac_id;
          }
          return site;
        });
      }

      if (
        props.system &&
        props.history.location.pathname.includes("/systems/detail/new")
      ) {
        setFormState({ ...formState, ...props.system });
        setId(props.systemId);
        setNewSystem(true);
      } else if (props.system) {
        setFormState({ ...formState, ...props.system });
        setId(props.systemId);
      } else if (
        props.history.location.pathname.includes("/systems/detail/new")
      ) {
        setNewSystem(true);
      } 
      setSites(allSites);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "siteId") {
      const siteName = event.target.options[event.target.selectedIndex].text;
      const siteMacId = event.target.options[
        event.target.selectedIndex
      ].getAttribute("data-id");
        setFormState({ ...formState, [name]: value, siteName, siteMacId });
        setMessage({ messageText: "", messageColor: "" });
    } else {
      setFormState({ ...formState, [name]: value });
      setMessage({ messageText: "", messageColor: "" });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!systemEquipId) {
      setMessage({ messageText: "You must enter a system id.", messageColor: "red" });
      return;
    }

    if (!systemName.trim()) {
      setMessage({ messageText: "You must enter a system name.", messageColor: "red" });
      return;
    }

    if (!siteId) {
      setMessage({ messageText: "You must enter a site.", messageColor: "red" });
      return;
    }

    let method;

    if (id) {
      method = "PUT";
    } else {
      method = "POST";
    }

    const data = {
      id,
      systemName: systemName.trim(),
      systemEquipId,
      siteId,
      siteMacId,
      manufacturer: manufacturer.trim(),
      model: model.trim(),
      locationNotes,
      resetHeartbeat
    }

    const updatedSystem = await updateSystem(method, data);

    if (updatedSystem && updatedSystem.err) {
      setMessage({ messageText: updatedSystem.err, messageColor: "red" });
    }
    else if (updatedSystem && updatedSystem.data && props.history.location.pathname.includes("/systems/detail/new")) {
      props.history.goBack();
    } else if (updatedSystem) {
      const dataToUpdate = {
        ...formState, 
        port: updatedSystem.port, 
        ipAddress: updatedSystem.systemIpAddress, 
        siteIp: updatedSystem.siteIpAddress,
        siteAddress: updatedSystem.siteAddress, 
        siteLatitude: updatedSystem.siteLatitude, 
        siteLongitude: updatedSystem.siteLongitude,
        siteAccessNotes: updatedSystem.siteAccessNotes,
        siteContactName: updatedSystem.siteContactName,
        siteContactEmail: updatedSystem.siteContactEmail,
        siteContactPhone: updatedSystem.siteContactPhone,
        siteContactNotes: updatedSystem.siteContactNotes,
        siteHeartbeat: updatedSystem.siteHeartbeat,
      }
      props.storeSystem(dataToUpdate);
    } else {
      setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
    }
  };

  const cancelForm = () => {
    if (props.cancelForm) {
      props.cancelForm();
    } else {
      props.history.push("/systems");
    }
  };

  const showResetHeartbeatInfo = () => {
    if (!resetHeartbeatInfo) {
      setResetHeartbeatInfo("commonly used when replacing system hardware");
    } else {
      setResetHeartbeatInfo("");
    }
  }

  return (
    <div className="main main-detail">
      <MainHeader componentName="System Details" />
      <form id="system-form" onSubmit={handleSubmit}>
        <div className="alarm-detail-section">
          <p className="heading"> SYSTEM </p>
          <p>
            <span className="detail">
              ID<span className="red">*</span>:{" "}
            </span>
            <select
              name="systemEquipId"
              value={systemEquipId}
              onChange={handleChange}
            >
              <option value="">Select a System</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((equipId, i) => (
                <option key={i} value={equipId}>
                  {"System " + equipId}
                </option>
              ))}
            </select>
          </p>
          <p>
            <span className="detail">
              Name<span className="red">*</span>:{" "}
            </span>
            <input
              type="text"
              name="systemName"
              value={systemName}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Manufacturer: </span>
            <input
              type="text"
              name="manufacturer"
              value={manufacturer}
              onChange={handleChange}
            />
          </p>
          <p>
            <span className="detail">Model:</span>
            <input
              type="text"
              name="model"
              value={model}
              onChange={handleChange}
            />
          </p>

          {!newSystem && (
            <p>
              <span className="detail">Reset Heartbeat<span className="dot" onClick={() => showResetHeartbeatInfo()}>i</span>:</span> <span className="reset-heartbeat-info">{resetHeartbeatInfo}</span>
              <select
                name="resetHeartbeat"
                value={resetHeartbeat}
                onChange={handleChange}
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </p>
          )}

          <div className="shadow-box"></div>

          <p className="heading"> SITE </p>
          {sites && (
            <p>
              <span className="detail">
                Name<span className="red">*</span>:{" "}
              </span>
              <select
                name="siteId"
                value={siteId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                {sites.map((site, i) => (
                  <option key={i} value={site.id} data-id={site.mac_id}>
                    {site.site_name}
                  </option>
                ))}
              </select>
            </p>
          )}
          {!sites && (
            <p>
              <span className="detail">
                Name<span className="red">*</span>:{" "}
              </span>
              <select
                name="siteId"
                value={siteId}
                onChange={handleChange}
              >
                <option value="">Select a Site</option>
                <option value="">You do not have any sites.</option>
              </select>
            </p>
          )}
          <p className="notes-div">
            <span className="detail">Location Notes: </span>
            <textarea
              className="system-form-textarea"
              type="text"
              maxLength="1000"
              name="locationNotes"
              value={locationNotes}
              onChange={handleChange}
            />
          </p>
          <button className="details-submit">Submit</button>
          <div
            className="details-form-footer"
            onClick={() => cancelForm()}
          >
            Cancel
          </div>
          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default withRouter(SystemDetailForm);
