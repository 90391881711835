import React, { useState, useEffect } from "react";
import MainHeader from "../MainHeader";
import AlarmSearch from "./AlarmSearch";
import AlarmTable from "./AlarmTable";
import AdvancedAlarmSearch from "./AdvancedAlarmSearch";
import "../../styles/alarm.css";
import { modifyUTCToLocalTime } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { getAllAlarms, getAlarmsBySiteId } from "../../utils/apis";

function Alarm() {

  const { siteId: paramsSiteId } = useParams();

  const [filterData, setFilterData] = useState({
    filterStringDateAndType: "",
    filterStringSiteAndSystem: "",
    filterString: false,
    alarmSearchBottomMargin: "advanced-search-bottom-margin"
  });

  const [filteredAlarms, setFilteredAlarms] = useState(false);
  const [emptySearchInput, setEmptySearchInput] = useState(false);
  const [advancedSearchFormOpen, setAdvancedSearchFormOpen] = useState(false);
  const [alarms, setAlarms] = useState(false);
  const [siteId, setSiteId] =  useState(-1);

  const { filterStringDateAndType, filterStringSiteAndSystem, filterString, alarmSearchBottomMargin } = filterData;

  useEffect(() => {
    getAlarmData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsSiteId]);

  const cleanAlarmData = (alarms) => {
    if (alarms.length > 0) {
      alarms = alarms.map((alarm) => {
        alarm.start_time = modifyUTCToLocalTime(alarm.start_time);
        if (!alarm.site_name) {
          alarm.site_name = alarm.mac_id;
        }
        if (!alarm.system_name) {
          alarm.system_name = alarm.ip_address;
        }
        return alarm;
      });
    }
    return alarms;
  }

  const getAlarmData = async (reset = false) => {
    let alarms;
    if (siteId !== paramsSiteId || reset === true) {
      if (paramsSiteId) {

        alarms = await getAlarmsBySiteId(paramsSiteId);

        if (alarms && alarms.length > 0) {  
          setAlarms(cleanAlarmData(alarms));
          setFilteredAlarms(cleanAlarmData(alarms));
        }
      } else {
        alarms = await getAllAlarms();
        if (alarms && alarms.length > 0) {
          setAlarms(cleanAlarmData(alarms));
          setFilteredAlarms(cleanAlarmData(alarms)); 
        }
      }

      setFilterData({ filterStringDateAndType: "", filterStringSiteAndSystem: "", filterString: false, alarmSearchBottomMargin: "advanced-search-bottom-margin" });
      setSiteId(paramsSiteId);
      setEmptySearchInput(true);
    }
  }

  const filterAlarms = (filteredAlarms, advancedSearch=false) => {
    if (!advancedSearch) {
      setFilteredAlarms(cleanAlarmData(filteredAlarms));
    } else {
      setFilteredAlarms(cleanAlarmData(filteredAlarms));
      setEmptySearchInput(true);
    }
  }

  const keepSearchInput = () => {
    setEmptySearchInput(false);
  }

  const toggleAdvancedSearchForm = () => {
    setAdvancedSearchFormOpen(!advancedSearchFormOpen);
  };

  const downloadAlarms = () => {

    let alarmsString = "Time, Site, System, Event, Level, Status\n";

    filteredAlarms.forEach( (alarm) => {
      alarmsString += `${alarm.start_time}, ${alarm.site_name}, ${alarm.system_name}, ${alarm.title}, ${alarm.level}, ${alarm.status}\n`;
    });

    const hiddenElement = document.createElement('a');  
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(alarmsString);  
    hiddenElement.target = '_blank';  
      
    //provide the name and title for the CSV file to be downloaded  
    if (filterStringDateAndType || filterStringSiteAndSystem) {
      hiddenElement.download = filterStringDateAndType + " - " + filterStringSiteAndSystem; 
    } else {
      hiddenElement.download = "Condor Monitoring Active " + (new Date()).toLocaleDateString();
    }
    
    hiddenElement.click();  
  }
  
  const closeAdvancedSearchForm = () => {
    setAdvancedSearchFormOpen(false);
  };

  const setFilterString = (filters) => {
    let filterStringDateAndType = "";
    let filterStringSiteAndSystem = "";

    if (filters.fromDate && filters.toDate) {
      filterStringDateAndType += filters.fromDate + " to " + filters.toDate + " | ";
    }

    if (filters.activeOnly) {
      filterStringDateAndType += "Active | "
    } else {
      filterStringDateAndType += "Active | Clear | "
    }

    if (filters.site) {
      filterStringSiteAndSystem += filters.site + " | ";
    }

    if (filters.system) {
      filterStringSiteAndSystem += filters.system + " | ";
    }

    filterStringDateAndType = filterStringDateAndType.trim().substring(0, filterStringDateAndType.length - 2);
    filterStringSiteAndSystem = filterStringSiteAndSystem.trim().substring(0, filterStringSiteAndSystem.length - 2);


    if(!filterStringSiteAndSystem && filterStringDateAndType === "Active ") {
      filterStringDateAndType = "";
      setFilterData({filterStringDateAndType: "", filterStringSiteAndSystem: "", filterString: false, alarmSearchBottomMargin: "advanced-search-bottom-margin"});
    } else {
      setFilterData({filterStringDateAndType, filterStringSiteAndSystem, filterString: true, alarmSearchBottomMargin: ""});
    }
  }

  const clearFilter = () => {
    getAlarmData(true);
  }

  return (
    <div className="main">
      <MainHeader componentName="Alarms" />
      <div className="table-wrapper">
        <AlarmSearch downloadAlarms ={downloadAlarms} alarmSearchBottomMargin={alarmSearchBottomMargin} keepSearchInput = {keepSearchInput} emptySearchInput = {emptySearchInput} alarms = {alarms} filteredAlarms={filteredAlarms} filterAlarms={filterAlarms} toggleAdvancedSearchForm={toggleAdvancedSearchForm}/>

        {advancedSearchFormOpen && <AdvancedAlarmSearch closeAdvancedSearchForm={closeAdvancedSearchForm} alarms={alarms} filterAlarms={filterAlarms} setFilterString={setFilterString}/>}

        <div id="filter-string-wrapper">
          <p className="filter-string">{filterStringDateAndType}</p> 
          <p className="filter-string">{filterStringSiteAndSystem}</p>
          {filterString && <p id="filter-reset" onClick={clearFilter}> Clear Filter <i id="clear-filter-arrow-icon" className="fas fa-angle-right"></i></p>}
        </div>

        <AlarmTable alarms={filteredAlarms} />
      </div>
    </div>
  );
}

export default Alarm;
