import axios from "axios";

const GOOGLE_MAP_API_KEY = "AIzaSyDi9Zb3mPN4kH9m-os9jC5_HZrVFl97ang";
let activeInfoWindow;

const icons = {
  "No Systems": {
    name: "No Systems",
    icon: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
  },
  "No Active Alarms": {
    name: "No Active Alarms",
    icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  },
  "Critical": {
    name: "Critical",
    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  },
  "Major": {
    name: "Major",
    icon: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  },
  "Minor": {
    name: "Minor",
    icon: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  },
  "Warning": {
    name: "Warning",
    icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
  },
};

const loadGoogleMapsScript = () => {
  // add google maps script
  const googleMapScript = document.createElement("script");
  googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
  window.document.body.appendChild(googleMapScript);
  return googleMapScript;
};

const getMapData = async () => {
  try {
    const mapData = await axios({
      url: "/api/map",
      method: "GET",
    });

    if (mapData.data.length === 0) {
      return false;
    } else {
      return mapData;
    }
  } catch (err) {
    return false;
  }
};

const createGoogleMap = (googleMapRef, center) => {
  return new window.google.maps.Map(googleMapRef.current, {
    zoom: 4.5,
    center: new window.google.maps.LatLng(center.lat, center.lon),
    mapTypeId: "terrain",
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP,
    },
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: true,
    mapTypeControl: true

  });
};

const createMarkers = (googleMap, mapInfo, handleClick) => {
  return mapInfo.forEach((site, i) => {
    const latLng = new window.google.maps.LatLng(site.latitude, site.longitude);

    //set marker colors
    let url = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";

    if (site.num_of_systems < 1) {
      url = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";
    }

    if (!site.clear) {
      if (site.level_value === 4) {
        url = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
      } else if (site.level_value === 3) {
        url = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
      } else if (site.level_value === 2) {
        url = "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      } else if (site.level_value === 1) {
        url = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
      }
    }

    //tooltip
    let tooltip = site.id;

    if (site.site_name) {
      tooltip = site.site_name;
    }

    // markers
    const marker = new window.google.maps.Marker({
      position: latLng,
      map: googleMap,
      icon: {
        url: url,
      },
      title: tooltip,
    });

    // info window content
    let content = "<p class='info-site-name'>" + site.site_name + "</p>";

    // if there are alarms
    if (site.level_value) {
      // link to alarm page with active alarms and lost heartbeat for site
      content +=
        "<a data-id='" +
        site.id +
        "' id='linkToAlarms' href='#'> See Alarms </a>";
    }

    //listener
    window.google.maps.event.addListener(marker, "click", function (event) {
      if (activeInfoWindow) {
        activeInfoWindow.close();
      }
      const infoWindow = new window.google.maps.InfoWindow({
        content: content,
      });

      //attaches click listener to anchor tag in info window
      window.google.maps.event.addListener(infoWindow, "domready", () => {
        if (document.getElementById("linkToAlarms")) {
          document
            .getElementById("linkToAlarms")
            .addEventListener("click", (e) => {
              e.preventDefault();
              const siteId = e.target.getAttribute("data-id");
              // sends site id to map component where will redirect to alarm component
              handleClick(siteId);
            });
        }
      });
      infoWindow.open(googleMap, marker);
      activeInfoWindow = infoWindow;
    });
  });
};

function createLegend(googleMap) {
  const legend = document.getElementById("legend");

  for (const key in icons) {
    const type = icons[key];
    const name = type.name;
    const icon = type.icon;
    const div = document.createElement("div");
    div.innerHTML = '<img src="' + icon + '"> ' + name;
    legend.appendChild(div);
  }
  googleMap.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
}

export default function initMap(googleMapRef, createLink) {
  const googleMapScript = loadGoogleMapsScript();

  googleMapScript.addEventListener("load", async () => {
    const data = await getMapData();

    if (data && data.data.mapInfo && data.data.mapInfo.length > 0) {

      //center of the united states
      data.data.center = {
        lat: "39.64566",
        lon: "-90.6836",
      };

      const googleMap = createGoogleMap(googleMapRef, data.data.center);

      createMarkers(googleMap, data.data.mapInfo, createLink);
      createLegend(googleMap);
    } else {
      const data = {
        data: {
          center: null,
        },
      };

      //center of the united states
      data.data.center = {
        lat: "39.64566",
        lon: "-90.6836",
      };

      createGoogleMap(googleMapRef, data.data.center);
    }
  });
}
