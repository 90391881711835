import React, { useState, useEffect } from "react";
import MainHeader from "./MainHeader";
import "../styles/alarmDetail.css";
import "../styles/site.css";
import "../styles/notification.css";
import { gateways } from "../utils/smsGateways";
import { getNotifications, updateNotifications } from "../utils/apis";
import auth from "../utils/auth";

const carriers = Object.keys(gateways);

function Notification() {

  const [formState, setFormState] = useState({
    critical: false,
    major: false,
    minor: false,
    warning: false,
    none: false,
    email: false,
    text: false,
    phone: "",
    phoneCarrier: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { critical, major, minor, warning, none, email, text, phone, phoneCarrier } = formState;
  const { messageText, messageColor } = message;

  useEffect( () => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      const notifications = await getNotifications();

      if (notifications) {
        setFormState({
          major: notifications.major,
          minor: notifications.minor,
          critical: notifications.critical,
          warning: notifications.warning,
          none: notifications.none,
          email: notifications.email_notification,
          text: notifications.text_notification,
          phone: notifications.phone,
          phoneCarrier: notifications.phone_carrier,
        });
      } else {
        setFormState({ ...formState, none: true });
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "none" && checked === true) {
      setFormState({ ...formState, [name]: checked, major: false, minor: false, critical: false, warning: false, email: false, text: false });
      setMessage({ messageText: "", messageColor: "" });
    } else {
      setFormState({ ...formState, [name]: checked, none: false });
      setMessage({ messageText: "", messageColor: "" });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let phone = formState.phone;

    let company = auth.user.company_ids;
    if (company === "[4]" && (minor || major || warning)) {
      setMessage({ messageText: "At this time you many only select to receive critical messages.", messageColor: "red" });
      return;
    }

    // check at least one alarm type selected
    if (!major && !critical && !minor && !warning && !none) {
      setMessage({ messageText: "You must select one alarm type or select none.", messageColor: "red" });
      return;
    }

    // check at least one notification method selected
    if (!none && !text && !email) {
      setMessage({ messageText: "You must select one notification method.", messageColor: "red" });
      return;
    }

    // if text selected, make sure includes a phone number
    if (text && (!phone || phone.length < 1)) {
      setMessage({ messageText: "You must provide a phone number when selecting text notification.", messageColor: "red" });
      return;
    }

    // if text selected, make sure includes a phone carrier
    if (text && !phoneCarrier) {
      setMessage({ messageText: "You must provide a phone carrier when selecting text notification.", messageColor: "red" });
      return;
    }

    if (phone) {
      // check if phone number valid
      const strippedPhone = phone
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "")
        .replace(/\s/g, "");

      const isInvalidPhone = isNaN(strippedPhone);

      if (isInvalidPhone || strippedPhone.length < 10) {
        setMessage({ messageText: "Please provide a 10 digit phone number using only dashes as a divider.", messageColor: "red" });
        return;
      }

      // adds dashes to stripped phone
      phone = `${strippedPhone.substring(0, 3)}-${strippedPhone.substring(
        3,
        6
      )}-${strippedPhone.substring(6, 10)}`;
    }

    try {

      const data = {
            major,
            critical,
            minor,
            warning,
            none,
            email,
            text,
            phone,
            phoneCarrier,
      }

      const updatedNotifications = await updateNotifications(data);

      if (updatedNotifications) {
        setMessage({ messageText: "Your preferences have been saved. If you selected text notifications, you should receive a text message in the next few minutes. If you don't receive a text, please contact customer service.", messageColor: "green" });
      } else {
        setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
      }
    } catch (err) {
      setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader componentName="Notifications" />
      <form id="notification-form" onSubmit={handleSubmit}>
        <div className="alarm-detail-section">
          <p className="heading"> ALARMS TO BE NOTIFIED ABOUT </p>

          <p>
            <input
              className="checkbox"
              name="critical"
              type="checkbox"
              checked={critical}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="critical">Critical</label>
          </p>

          <p>
            <input
              className="checkbox"
              name="major"
              type="checkbox"
              checked={major}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="major">Major</label>
          </p>

          <p>
            <input
              className="checkbox"
              name="minor"
              type="checkbox"
              checked={minor}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="minor">Minor</label>
          </p>

          <p>
            <input
              className="checkbox"
              name="warning"
              type="checkbox"
              checked={warning}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="warning">Warning</label>
          </p>

          <p>
            <input
              className="checkbox"
              name="none"
              type="checkbox"
              checked={none}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="none">None</label>
          </p>

          <div id="notification-shadow-box" className="shadow-box"></div>

          <p className="heading"> NOTIFICATION METHOD </p>

          <p>
            <input
              className="checkbox"
              name="email"
              type="checkbox"
              checked={email}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="email">Email</label>
          </p>

          <p>
            <input
              className="checkbox"
              name="text"
              type="checkbox"
              checked={text}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="text">Text</label>
          </p>

          <p>
            <span className="detail">Phone: </span>
            <input
              id="notification-phone-input"
              type="text"
              name="phone"
              placeholder="ex. 919-333-4444"
              value={phone}
              onChange={handleInputChange}
            />
          </p>

          <p>
            <span className="detail">Carrier: </span>
            <select
              name="phoneCarrier"
              value={phoneCarrier}
              onChange={handleInputChange}
            >
              <option value="">Select a Carrier</option>
              {carriers.map((carrier, i) => (
                <option key={i} value={carrier}>
                  {carrier}
                </option>
              ))}
            </select>
          </p>

          <button id="notification-submit" className="details-submit">
            Submit
          </button>

          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default Notification;