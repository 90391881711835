import React, { useState, useEffect } from "react";
import auth from "../../utils/auth";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import "../../styles/auth.css";
import { withRouter } from "react-router-dom";

function LoginForm(props) {

  const [formState, setFormState] = useState({
    email: "",
    password: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { email, password } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    props.clearLogoutMsgTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === "" || password === "") {
      setMessage({
        messageText: "Please complete all fields.",
        messageColor: "red",
      });
      return;
    }
    const isLoggedIn = await auth.login(email, password);
    if (isLoggedIn) {
      props.startDefaultLogoutMsgTimer();
      props.history.push("/sites"); //change back to dashboard later *************************
    } else {
      setMessage({
        messageText: "Your email or password is incorrect. Please try again",
        messageColor: "red",
      });
      return;
    }
  };

  return (
    <div className="auth-card">
      <form onSubmit={handleSubmit}>
        <div className="auth-form-header">
          <img className="form-logo" src={logo} alt="condor logo" />
          <span>Welcome Back</span>
        </div>
        <input
          className="auth-input"
          type="text"
          name="email"
          placeholder="email"
          onChange={handleChange}
        />
        <input
          className="auth-input"
          type="password"
          name="password"
          placeholder="password"
          onChange={handleChange}
        />
        <button className="auth-submit">Login</button>
      </form>
      <div className="auth-form-footer">
        <Link id="get-password-form" to="/password">
          Forgot Password?
        </Link>
      </div>

      {messageText && (
        <p className={messageColor}>{messageText}</p>
      )}
    </div>
  );
}

export default withRouter(LoginForm);