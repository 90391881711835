import React, { useState, useEffect } from "react";
import "../../styles/alarmDetail.css";
import "../../styles/site.css";
import SystemDetailForm from "./SystemDetailForm";
import SystemDetailCards from "./SystemDetailCards";
import { modifyUTCToLocalTime } from "../../utils/helpers";
import { getSystemDetails } from "../../utils/apis";
import { useParams } from "react-router-dom";

function SystemDetail() {

  const [systemDetails, setSystemDetails] = useState({
    id: "",
    ipAddress: "",
    siteIp: "",
    port: "",
    systemHyperlink: "",
    heartbeat: "",
    heartbeatActive: "",
    siteAddress: "", 
    siteLatitude: "", 
    siteLongitude: "",
    siteAccessNotes: "",
    siteContactName: "",
    siteContactEmail: "",
    siteContactPhone: "",
    siteContactNotes: "",
    siteHeartbeat: ""
  });

  const [systemOverview, setSystemOverview] = useState({
    systemEquipId: "",
    systemName: "",
    manufacturer: "",
    model: "",
    siteId: "",
    siteName: "",
    siteMacId: "",
    locationNotes: ""
  });

  const [editData, setEditData] = useState(false);

  const [updatingSystem, setUpdatingSystem] = useState(true);

  const { systemId } = useParams();

  useEffect(() => {
    if (updatingSystem === true) {
      storeSystemDetails();
      setUpdatingSystem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingSystem]);

  const storeSystemDetails = async () => {

    const systemDetails = await getSystemDetails(systemId);

    if (systemDetails) {

      if (systemDetails.heartbeat) {
        systemDetails.heartbeat = modifyUTCToLocalTime(
          systemDetails.heartbeat
        );
      }

      if (systemDetails.site_heartbeat) {
        systemDetails.site_heartbeat = modifyUTCToLocalTime(
          systemDetails.site_heartbeat
        );
      }
      setSystemDetails({
        id: systemDetails.id,
        ipAddress: systemDetails.ip_address,
        siteIp: systemDetails.site_ip,
        port: systemDetails.port,
        systemHyperlink: systemDetails.system_hyperlink,
        heartbeat: systemDetails.heartbeat,
        heartbeatActive: systemDetails.heartbeat_active,
        siteAddress: systemDetails.site_address, 
        siteLatitude: systemDetails.latitude, 
        siteLongitude: systemDetails.longitude,
        siteAccessNotes: systemDetails.access_notes,
        siteContactName: systemDetails.contact_name,
        siteContactEmail: systemDetails.email,
        siteContactPhone: systemDetails.phone,
        siteContactNotes: systemDetails.contact_notes,
        siteHeartbeat: systemDetails.site_heartbeat
      });

      setSystemOverview({
        systemEquipId: systemDetails.equip_id,
        systemName: systemDetails.system_name,
        manufacturer: systemDetails.manufacturer,
        model: systemDetails.model,
        siteId: systemDetails.site_id,
        siteName: systemDetails.site_name,
        siteMacId: systemDetails.mac_id,
        locationNotes: systemDetails.location_notes,
      });
    }
  };

  const openDetailForm = () => {
    setEditData(true);
  };

  const openDetailCards = () => {
    setEditData(false);
  };

  // Used in SystemDetailForm submit event
  const updateSystem = (updatedSystemData) => {
    setSystemDetails({...systemDetails, ...updatedSystemData});
    setEditData(false);
    setUpdatingSystem(true);
  };

  return (
    <div>
      {!editData && (
        <SystemDetailCards
          system ={{...systemDetails, ...systemOverview}}
          openDetailForm={openDetailForm}
        />
      )}

      {editData && (
        <SystemDetailForm
          system={systemOverview}
          systemId={systemDetails.id}
          storeSystem={updateSystem}
          cancelForm={openDetailCards}
        />
      )}
    </div>
  );
}

export default SystemDetail;
