import React, { useState, useEffect } from "react";
import "../../styles/alarmSearch.css";

function AlarmSearch(props) {

  const [formState, setFormState] = useState({
    searchInput: ""
  });
  const { searchInput } = formState;

  useEffect(() => {
    if (props.emptySearchInput) {
      setFormState({ ...formState, searchInput: "" });
      props.keepSearchInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.emptySearchInput]);

  const filterBySearchValue = (searchValue) => {
    searchValue = searchValue.toLowerCase();
    const filteredAlarms = props.alarms.filter(alarm => {
      const alarmString = (alarm.level + alarm.site_name + alarm.start_time + alarm.status + alarm.system_name + alarm.title).toLowerCase();
      return alarmString.indexOf(searchValue) !== -1; 
    });
    props.filterAlarms(filteredAlarms);
    return filteredAlarms;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    filterBySearchValue(value);
  }

  return (
      <div id="search-area" className={props.alarmSearchBottomMargin}>
        <div id="search-input-div">
          <i id="search-icon" className="fas fa-search"></i>
          <input type="text" id="search" placeholder="Search" name="searchInput" value={searchInput} onChange={handleChange}/>
        </div>
        <p id="advanced-search" onClick={props.toggleAdvancedSearchForm}> <i  id="advanced-search-icon" className="fas fa-search-plus"></i> Advanced Search  </p>
        <p id="download" onClick={props.downloadAlarms}> <i id="download-icon" className="fas fa-download"></i> Download  </p>
      </div>
  );
}

export default AlarmSearch;
