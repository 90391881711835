import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/leftNav.css";

function LeftNavbar() {
  return (
    <nav id="left-nav">
      <ul>
        {/* <li>
          <i className="fas fa-chart-line"></i>
          <NavLink
            exact
            activeClassName="nav-selected"
            className="nav-link"
            to="/dashboard"
          >
            Dashboard
          </NavLink>
        </li> */}
        <li>
          <i className="fas fa-bell"></i>
          <NavLink
            exact
            activeClassName="nav-selected"
            className="nav-link"
            to="/alarms"
          >
            Alarms
          </NavLink>
        </li>
        <li>
          <i className="fas fa-map-marked-alt"></i>
          <NavLink
            exact
            activeClassName="nav-selected"
            className="nav-link"
            to="/map"
          >
            Map
          </NavLink>
        </li>
        <li>
          <i className="fas fa-map-marker-alt"></i>
          <NavLink
            exact
            activeClassName="nav-selected"
            className="nav-link"
            to="/sites"
          >
            Sites
          </NavLink>
        </li>
        <li>
          <i className="fas fa-microchip"></i>
          <NavLink
            exact
            activeClassName="nav-selected"
            className="nav-link"
            to="/systems"
          >
            Systems
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default LeftNavbar;
