import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/auth.css";
import logo from "../../images/logo.png";
import auth from "../../utils/auth";

function PasswordForgotForm() {

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { messageText, messageColor } = message;

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // sends email to server to create token and send email to user
    auth.requestPasswordReset(email);
    setMessage({ messageText: "We have sent a password reset link to your email.", messageColor: "green" });
  };

  return (
    <div className="auth-card">
      <form onSubmit={handleSubmit}>
        <div className="auth-form-header">
          <img className="form-logo" src={logo} alt="condor logo" />
          <span>Reset your Password</span>
        </div>
        <p id="reset-password-text">Forgot your password? That's okay - enter your email address and we'll email you instructions to reset your password.</p>
        <input
          className="auth-input"
          type="email"
          name="email"
          placeholder="Email Address *"
          onChange={handleChange}
        />
        <button className="auth-submit">Reset Password</button>
      </form>
      <div className="auth-form-footer">
        <Link className="cancel-form" to="/login">
          Cancel
        </Link>
      </div>
      {messageText && (
        <p className={messageColor}>{messageText}</p>
      )}
    </div>
  );
}

export default PasswordForgotForm;