import React, { useState, useEffect } from "react";
import auth from "../utils/auth";
import MainHeader from "./MainHeader";
import { condorCustomerServiceEmail } from "../constants.js";
import "../styles/alarmDetail.css";
import "../styles/site.css";
import { getAllCompanies, getUserByEmail, postUserInvite } from "../utils/apis.js";

function UserRequestForm() {

  const [message, setMessage] = useState({messageText: "", messageColor: ""});
  const [admin, setAdmin] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [firstCompany, setFirstCompany] = useState(false);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyId: "",
    companyName: "",
    newCompany: "",
  });

  const { firstName, lastName, email, companyId, companyName, newCompany } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      const userCompany = parseInt(
        auth.user.company_ids.replace("[", "").replace("]", "").split(", ")
      );
      // if current user is a condor admin
      if (userCompany !== 0) {
        // store user's company in state
        setFormState({ ...formState, companyId: userCompany });
      } else {
        // get all companies and display companies in select
        const allCompanies = await getAllCompanies();
        if (allCompanies) {
          allCompanies.unshift({ id: "0", name: "Not Listed" });
          setCompanies(allCompanies);
          setAdmin(true);
        } else {
          setFirstCompany(true);
          setAdmin(true); 
        }
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "companyId") {
      setFormState({ ...formState, companyName: event.target.options[event.target.selectedIndex].text });
    }
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.trim() === "") {
      setMessage({ messageText: "Please enter the requested user's email.", messageColor: "red" });
      return;
    }

    if ((companyId === "0" || companyId === "") && newCompany === "") {
      if (admin === true) {
        setMessage({ messageText: "You must select a company or enter a new company name.", messageColor: "red" });
      } else {
        setMessage({ messageText: `There was an error submitting your request. Please try again or contact customer service at ${condorCustomerServiceEmail}.`, messageColor: "red" });
      }
      return;
    }

    // see if user already exists
    const user = await getUserByEmail(email);

    if (user) {
      setMessage({ messageText: "This email is already registered with our system. Please enter a different email.", messageColor: "blue" });
      return;
    }
    setMessage({ messageText: "sending...", messageColor: "blue" });

    try {
      const data = {
        firstName:firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        companyId,
        companyName,
        newCompany: newCompany.trim(),
      }

      const inviteStatus = await postUserInvite(data);

      if (inviteStatus === "invited") {
        setFormState({
          firstName: "",
          lastName: "",
          email: "",
          companyId: "",
          companyName: "",
          newCompany: "",
        });
        setMessage({ messageText: `An invitation has been sent to ${email}.`, messageColor: "green" });
      } else {
        setMessage({ messageText: `There was an error submitting your request. Please try again or contact customer service at ${condorCustomerServiceEmail}.`, messageColor: "red" });
      }
    } catch (err) {
      setMessage({ messageText: `There was an error submitting your request. Please try again or contact customer service at ${condorCustomerServiceEmail}.`, messageColor: "red" });
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader componentName="User Request" />
      <form id="user-form" onSubmit={handleSubmit}>
        <div className="alarm-detail-section">
          <p className="heading">NEW USER</p>
          <p>
            <span className="detail">First Name: </span>
            <input
              className="auth-input"
              type="text"
              name="firstName"
              onChange={handleChange}
              value={firstName}
            />
          </p>
          <p>
            <span className="detail">Last Name: </span>
            <input
              className="auth-input"
              type="text"
              name="lastName"
              onChange={handleChange}
              value={lastName}
            />
          </p>
          <p>
            <span className="detail">Email *: </span>
            <input
              className="auth-input"
              type="text"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </p>

          {companies.length > 0 && (
            <p>
              <span className="detail">Company *: </span>
              <select
                name="companyId"
                value={companyId}
                onChange={handleChange}
              >
                <option value="">Select a Company</option>
                {companies.map((company, i) => (
                  <option key={i} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </select>
            </p>
          )}
          {(companyId === "0" || firstCompany) && (
            <p>
              <span className="detail">New Company Name *: </span>
              <input
                className="auth-input"
                type="text"
                name="newCompany"
                onChange={handleChange}
                value={newCompany}
              />
            </p>
          )}
          <button className="details-submit">Submit</button>

          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default UserRequestForm;
