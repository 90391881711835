const gateways = {
  "AT&T": "@txt.att.net",
  "Boost Mobile": "@sms.myboostmobile.com",
  "C-Spire": "@cspire1.com",
  "Consumer Cellular": "@mailmymobile.net",
  Cricket: "@sms.cricketwireless.net",
  "Google Fi (Project Fi)": "@msg.fi.google.com",
  "Metro PCS": "@mymetropcs.com",
  "Mint Mobile": "@mailmymobile.net",
  "Page Plus": "@vtext.com",
  "Republic Wireless": "@text.republicwireless.com",
  "Simple Mobile": "@smtext.com",
  Sprint: "@messaging.sprintpcs.com",
  "T-Mobile": "@tmomail.net",
  Ting: "@message.ting.com",
  Tracfone: "@mmst5.tracfone.com",
  "U.S. Cellular": "@email.uscc.net",
  Verizon: "@vtext.com",
  "Virgin Mobile": "@vmobl.com",
  "Xfinity Mobile": "@vtext.com",
};

module.exports = {
  gateways
}
