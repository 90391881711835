import React, { useState, useEffect } from "react";
import SiteTable from "./SiteTable";
import "../../styles/alarm.css";
import { withRouter, useHistory } from "react-router-dom";
import { getAllSites } from "../../utils/apis";

function Site() {

  const [sites, setSites] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {

      let sites = await getAllSites();

      if (sites && sites.length > 0) {
        sites = sites.map(site=> {
          if (!site.site_name) {
            site.site_name = site.mac_id;
          }
          return site;
        });
      }

      setSites(sites);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openNewSiteForm = () => {
    history.push("/sites/detail/new");
  };

  return (
    <div>
      <SiteTable
        sites={sites}
        openNewSiteForm={openNewSiteForm}
      />
    </div>
  );
}

export default withRouter(Site);
