import React, { useEffect } from "react";
import MainHeader from "./MainHeader";
import initMap from "../utils/map";
import "../styles/map.css";
import { useHistory } from "react-router-dom";

function Map() {
  const googleMapRef = React.createRef();
  const history = useHistory();

  useEffect(()=> {
    initMap(googleMapRef, handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (siteId) => {
    history.push(`/alarms/site/${siteId}`);
  };

  return (
    <div id="map-main-div" className="main">
      <MainHeader componentName="Map">
        <p> Click on a site pin for more information. </p>
      </MainHeader>
      <div id="google-map" ref={googleMapRef} />
      <div id="legend"><h3>Legend</h3></div>
    </div>
  );
}

export default Map;
