import React, { useState, useEffect } from 'react';
import MainHeader from "./MainHeader";
import "../styles/alarmDetail.css";
import "../styles/site.css";
import "../styles/notification.css";
import { getTicketEmail, updateTicketSystemEmail } from "../utils/apis";

function Ticket() {

  const [formState, setFormState] = useState({
    ticketSystemEmail: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { ticketSystemEmail } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    // async function inside b/c effect callbacks are synchronous to prevent race conditions
    async function getData() {
      const email = await getTicketEmail();

      if (email) {
        setFormState({ ...formState, ticketSystemEmail: email });
      } 
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // check at least one alarm type selected
    if (!ticketSystemEmail.trim()) {
      setMessage({ messageText: "You must enter an email.", messageColor: "red" });
      return;
    }

    try {
      const updatedCompany = await updateTicketSystemEmail(ticketSystemEmail.trim());

      if (updatedCompany) {
        setMessage({ messageText: "Your ticketing system email has been saved.", messageColor: "green" });
      } else {
        setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
      }
    } catch (err) {
      setMessage({ messageText: "There was an error saving this form. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader componentName="Notifications" />
      <form id="notification-form" onSubmit={handleSubmit}>
        <div className="alarm-detail-section">

          <p className="heading"> TICKET SYSTEM </p>

          <p>Enter an email to connect to an independent ticketing system.</p>

          <p>
            <span className="detail">Email<span className="red">*</span>: </span>
            <input
              id="notification-phone-input"
              type="text"
              name="ticketSystemEmail"
              value={ticketSystemEmail}
              onChange={handleChange}
            />
          </p>

          <button id="notification-submit" className="details-submit">
            Submit
          </button>

          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default Ticket;
