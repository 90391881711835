import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "../../styles/alarmDetail.css";
import "../../styles/site.css";
import SiteDetailForm from "./SiteDetailForm";
import SiteDetailCards from "./SiteDetailCards";
import { modifyUTCToLocalTime } from "../../utils/helpers";
import { getSiteDetails } from "../../utils/apis";

function SiteDetail() {

  const [siteDetails, setSiteDetails] = useState({
    id: "",
    siteIp: "",
    macId: "", 
    siteName: "",
    siteAddress: "",
    longitude: "",
    latitude: "",
    accessNotes: "",
    contactName: "",
    email: "",
    phone: "",
    contactNotes: "",
    heartbeat: "",
    fileStoragePlan: null,
  });

  const { siteId: paramsSiteId } = useParams();

  const [editData, setEditData] = useState(false);
  const [updatingSite, setUpdatingSite] = useState(true);

  useEffect(() => {
    if (updatingSite === true) {
      storeSiteDetails();
      setUpdatingSite(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingSite]);

  const storeSiteDetails = async () => {

      const siteDetails = await getSiteDetails(paramsSiteId);

      if (siteDetails) {
        if (siteDetails.heartbeat) {
          siteDetails.heartbeat = modifyUTCToLocalTime(siteDetails.heartbeat);
        }
        setSiteDetails({
          id: siteDetails.id,
          macId: siteDetails.mac_id,
          siteName: siteDetails.site_name || "",
          siteAddress: siteDetails.site_address || "",
          longitude: siteDetails.longitude || "",
          latitude: siteDetails.latitude || "",
          accessNotes: siteDetails.access_notes || "",
          contactName: siteDetails.contact_name || "",
          email: siteDetails.email || "",
          phone: siteDetails.phone || "",
          contactNotes: siteDetails.contact_notes || "",
          siteIp: siteDetails.site_ip,
          heartbeat: siteDetails.heartbeat,
          fileStoragePlan: siteDetails.file_storage_plan
        });
      } 
  };

  const openDetailForm = () => {
    setEditData(true);
  };

  const openDetailCards = () => {
    setEditData(false);
  };

  const updateSite = (updatedSiteData) => {
    setEditData(false);
    setSiteDetails({ ...siteDetails, ...updatedSiteData});
    setUpdatingSite(true);
  };

  return (
    <div>
      {!editData && (
        <SiteDetailCards
          site={siteDetails}
          openDetailForm={openDetailForm}
        />
      )}

      {editData && (
        <SiteDetailForm
          site={siteDetails}
          storeSite={updateSite}
          cancelForm={openDetailCards}
        />
      )}
    </div>
  );
}

export default SiteDetail;