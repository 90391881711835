import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import "./styles/reset.css";
import "./App.css";
import "./index.css";
import "./styles/alarmDetail.css";
import "./styles/site.css";

import auth from "./utils/auth";

// import Profile from "./components/Profile";
// import Dashboard from "./components/Dashboard";
import Map from "./components/Map";
import Sites from "./components/site/Site";
import SiteDetail from "./components/site/SiteDetail";
import Systems from "./components/system/System";
import SystemDetail from "./components/system/SystemDetail";
import SystemDetailForm from "./components/system/SystemDetailForm";
import SiteDetailForm from "./components/site/SiteDetailForm";
import Alarms from "./components/alarm/Alarm";
import AlarmDetail from "./components/alarm/AlarmDetail";
// import Groups from "./components/Group";
import Notifications from "./components/Notification";
import Tickets from "./components/Ticket";
import SignupForm from "./components/auth/SignupForm";
import LoginForm from "./components/auth/LoginForm";
import PasswordForgotForm from "./components/auth/PasswordForgotForm";
import PasswordResetForm from "./components/auth/PasswordResetForm";
// import NotFound from "./components/NotFound";
import TopNavbar from "./components/TopNavbar";
import LeftNavbar from "./components/LeftNavbar";
import UserRequestForm from "./components/UserRequestForm";

import FileTable from "./components/file/FileTable";
import NewFileUpload from "./components/file/NewFileUpload";
import FileDescriptionEdit from "./components/file/FileDescriptionEdit";

let secondsInterval;
let logoutMsgTimer;

class App extends Component {

  state = {
    isLoading: true,
    openLogoutMsg: false,
    seconds: 60,
    set8HrTimer: false,
  };

  // show logout timer message after an hour of being logged on
  startDefaultLogoutMsgTimer = ()  => {
    logoutMsgTimer = setTimeout(()=> { 
      this.startOneMinToLogoutTimer();
      this.setState({openLogoutMsg: true});
    }, 3600000);
  };

  clearLogoutMsgTimer = () => {
    clearTimeout(logoutMsgTimer);
    clearInterval(secondsInterval)
    this.setState({openLogoutMsg: false, seconds: 60});
  }

  // logs user out after 8 hours
  start8HrsToLogoutTimer = ()  => {
    setTimeout(()=> { 
      auth.logout("client - 8 hr timeout")
      document.location.reload();
    }, 28800000);
  };

  // hide logout timer message 
  closeLogoutMsg = ()  => {
    if (this.state.set8HrTimer === true) {
      this.start8HrsToLogoutTimer();
    } else {
      this.startDefaultLogoutMsgTimer();
    }
    clearInterval(secondsInterval)
    this.setState({openLogoutMsg: false, seconds: 60});
  };

  // log user out if they don't elect to stay signed on
  startOneMinToLogoutTimer = () => {
    let seconds = this.state.seconds;
    secondsInterval = setInterval(()=>{
      if (seconds <= 0) {
        this.logOut();
        this.setState({seconds: 0})
      }
      this.setState({seconds: seconds--})
    }, 1000)
  }

  logOut = ()  => {
    auth.logout("client - minute timeout");
    clearInterval(secondsInterval)
    this.setState({openLogoutMsg: false, seconds: 60});
    document.location.reload();
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked})
  };

  async componentWillMount() {
    // does not have to be logged in to ping server, b/c just means page open in browser and not closed via tab
    let isLoggedIn = await auth.verifyUser();

    if (this.props.history.location.pathname.includes("/signup") || this.props.history.location.pathname.includes("/password")) {
    } else if (isLoggedIn) {

      this.startDefaultLogoutMsgTimer();
      auth.pingServer();

      if (
        this.props.history.location.pathname === "/login" ||
        this.props.history.location.pathname === "/"
      ) {
        this.props.history.push("/sites"); // change back to dashboard later *********************************
      }
    } else {
      this.props.history.push("/login");
    }
    this.setState({ isLoading: false });
  }

  displayRoutes = ()  => {
    return (
      <Router>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />

            <Route exact path="/files" component={FileTable} />
            <Route exact path="/api/files/upload" component={NewFileUpload} />
            <Route exact path="/api/files/edit/:id" component={FileDescriptionEdit} />


            {/* <ProtectedRoute exact path="/profile" component={Profile} /> */}
            {/* <ProtectedRoute exact path="/dashboard" component={Dashboard} /> */}
            <ProtectedRoute exact path="/alarms" component={Alarms} />
            <ProtectedRoute
              exact
              path="/alarms/site/:siteId"
              component={Alarms}
            />
            <ProtectedRoute
              exact
              path="/alarms/detail/:alarmId"
              component={AlarmDetail}
            />
            {/* <ProtectedRoute
              exact
              path="/alarms/search"
              component={AdvancedAlarmSearch}
            /> */}
            <ProtectedRoute exact path="/map" component={Map} />
            <ProtectedRoute exact path="/sites" component={Sites} />
            <ProtectedRoute
              exact
              path="/sites/detail/new"
              component={SiteDetailForm}
            />
            <ProtectedRoute
              exact
              path="/sites/detail/:siteId"
              component={SiteDetail}
            />
            <ProtectedRoute exact path="/systems" component={Systems} />
            <ProtectedRoute
              exact
              path="/systems/site/:siteId"
              component={Systems}
            />
            <ProtectedRoute
              exact
              path="/systems/detail/new"
              component={SystemDetailForm}
            />
            <ProtectedRoute
              exact
              path="/systems/detail/:systemId"
              component={SystemDetail}
            />

            {/* <ProtectedRoute exact path="/groups" component={Groups} /> */}
            <ProtectedRoute
              exact
              path="/notifications"
              component={Notifications}
            />
            <ProtectedRoute
              exact
              path="/tickets"
              component={Tickets}
            />
            {/* <ProtectedRoute exact path="/user/form" component={UserRequestForm} /> */}

            <ProtectedRoute
              exact
              path="/user/form"
              component={UserRequestForm}
            />

            <Route exact path="/signup/:token" component={SignupForm} />
            {/* <Route exact path="/login" component={LoginForm} /> */}
            <Route exact path="/login"> <LoginForm startDefaultLogoutMsgTimer={this.startDefaultLogoutMsgTimer} clearLogoutMsgTimer={this.clearLogoutMsgTimer}/> </Route>
            <Route exact path="/password" component={PasswordForgotForm} />
            <Route
              exact
              path="/password/:token"
              component={PasswordResetForm}
            />
            <Route path="*" render={() => <Redirect to="/login" />} />
          </Switch>
        </Router>
    )
  }

  displayLogoutMsg = ()  => {
    return (
      <div className="overlay-div">
      <div className="main main-detail delete-item-div">
          <div className="alarm-detail-section">
            <p className="heading">
              <span className="blue">ARE YOU STILL THERE?</span>
            </p>
            <p className="bold">
              {" "}
              Your session will time out in {this.state.seconds} seconds. Click "Stay Signed On" to continue.
            </p>
            <button onClick={this.closeLogoutMsg} id="logout-msg-btn" className="details-submit">Stay Signed On</button>
              <input
                className="checkbox"
                name="set8HrTimer"
                type="checkbox"
                checked={this.state.set8HrTimer}
                onChange={this.handleCheckboxChange}
              />
              <label id="eight-hr-timer-label" htmlFor="set8HrTimer">Keep me signed in for 8 hours.</label>
          </div>
      </div>
    </div>
    )
  }

  // putting the logout message in its own if else instead of in the last else is required to get the login component to render when the user selected No
  // otherwise, this.props.history.push("/login") updates the url to say /login but the login component doesn't render
  render() {
    if (this.state.isLoading) {
      return <div>loading......</div>;
    } else if (this.state.openLogoutMsg) {
      return (
        <div>
          {this.state.openLogoutMsg && this.displayLogoutMsg()}
          {this.displayRoutes()}
        </div>)
    } else {
      return (
      <div>
        {this.displayRoutes()}
      </div>)
    }
  }
}

export default withRouter(App);

class ProtectedRoute extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };
  }

  async componentWillMount() {
    let isLoggedIn = await auth.verifyUser();

    if (isLoggedIn) {
      this.setState(() => ({ isLoading: false, isLoggedIn: true }));
    } else {
      this.setState(() => ({ isLoading: false, isLoggedIn: false }));
    }
  }

  async componentWillUpdate() {
    let isLoggedIn = await auth.verifyUser();

    if (!isLoggedIn) {
      auth.logout("client component mount");
      return;
    }

    if (isLoggedIn && this.state.isLoggedIn === false) {
      this.setState(() => ({ isLoading: false, isLoggedIn: true }));
    }

    if (!isLoggedIn && this.state.isLoggedIn === true) {
      this.setState(() => ({ isLoading: false, isLoggedIn: false }));
    }
  }

  render() {
    return this.state.isLoading ? null : this.state.isLoggedIn ? (
      <div>
        <TopNavbar />  
        <LeftNavbar />
        <Route
          path={this.props.path}
          component={this.props.component}
          exact={this.props.exact}
        />
      </div>
    ) : (
      <Redirect
        to={{ pathname: "/login", state: { from: this.props.location } }}
      />
    );
  }
}
