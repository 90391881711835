import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/alarmDetail.css";
import "../../styles/site.css";
import MainHeader from "../MainHeader";
import FileTable from "../file/FileTable";
import NewFileUpload from "../file/NewFileUpload";
import FileDescriptionEdit from "../file/FileDescriptionEdit";
import { withRouter } from "react-router-dom";
import { deleteSite } from "../../utils/apis";

function SiteDetailCards(props) {

  const id = props.site.id;
  const siteName = props.site.siteName;

  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [fileStorageRemaining, setFileStorageRemaining] = useState(null);

  const [formState, setFormState] = useState({
    siteToDelete: ""
  });
  const [fileComponents, setFileComponents] = useState({
    fileTable: true,
    addFileForm: false,
    editFileDescForm: false,
  });
  const [fileToEdit, setFileToEdit] = useState({
    fileToEditId: null,
    fileToEditName: "",
    fileToEditSize: "",
    fileToEditDesc: "",
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { fileTable, addFileForm, editFileDescForm } = fileComponents;
  const { fileToEditId, fileToEditName, fileToEditSize, fileToEditDesc } = fileToEdit;
  const { siteToDelete } = formState;
  const { messageText, messageColor } = message;

  const openDeleteItemForm = () => {
    setOpenDeleteForm(true);
  };

  const closeDeleteItemForm = () => {
    setOpenDeleteForm(false);
  };

  const openAddFileForm = () => {
    setFileComponents({
      addFileForm: true, fileTable: false, editFileDescForm: false
    });
  }

  const openEditFileDescForm = (fileId, fileName, fileSize, fileDesc) => {
    if (fileId) {
      setFileComponents({
        addFileForm: false, fileTable: false, editFileDescForm: true
      });
      setFileToEdit({fileToEditDesc: fileDesc, fileToEditName: fileName, fileToEditSize: fileSize, fileToEditId: fileId});
    }
  }

  const openFileTable = () => {
    setFileComponents({
      addFileForm: false, fileTable: true, editFileDescForm: false
    });
  }

  const updateFileStorageInfo = (fileStorageUsed) => {
    const fileStorageRemaining = Math.round((props.site.fileStoragePlan - fileStorageUsed) * 1000)/1000;
    setFileStorageRemaining(fileStorageRemaining);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmitDeleteForm = async (event) => {
    event.preventDefault();
    // confirm typed site name correctly
    if (siteToDelete.trim() === siteName.trim()) {

      const deletedSite = await deleteSite(id);

      if (deletedSite) {
        props.history.push("/sites");
      } else {
        setMessage({ messageText: "There was an error deleting this site. Please try again.", messageColor: "red" });
        return;
      }
    } else {
      setMessage({ messageText: "You did not enter the site name correctly. Please try again.", messageColor: "red" });
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader
        componentName="Site Details"
        openUpdateItemForm={() => props.openDetailForm()}
        openDeleteItemForm={() => openDeleteItemForm()}
      />
      {openDeleteForm && (
        <div className="overlay-div">
          <div className="main main-detail delete-item-div">
            <form id="site-form" onSubmit={handleSubmitDeleteForm}>
              <div className="alarm-detail-section">
                <p className="heading">
                  <span className="red">ARE YOU SURE?</span>
                </p>

                <p className="bold">
                  {" "}
                  You are about to delete the following site and its
                  associated sytems and alarms.
                </p>
                <p className="item-to-delete-description">
                  {" "}
                  SITE ID: {props.site.macId}
                </p>
                <p className="item-to-delete-description">
                  {" "}
                  SITE NAME: {siteName}
                </p>
                <p>
                  <span className="detail">
                    Type <span className="blue">{siteName}</span>{" "}
                    to confirm:
                  </span>
                  <input
                    type="text"
                    name="siteToDelete"
                    value={siteToDelete}
                    onChange={handleChange}
                  />
                </p>
                <button className="details-submit">DELETE THIS SITE</button>
                <div
                  className="details-form-footer"
                  onClick={() => closeDeleteItemForm()}
                >
                  Cancel
                </div>
                {messageText && (
                  <p className={messageColor}>{messageText}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      <div>
        <div className="alarm-detail-section">
          <p className="heading">SITE</p>
          <div className="detail-item">
            <p className="detail">ID: </p>
            <p>{props.site.macId}</p>
          </div>
          <div className="detail-item">
            <p className="detail">IP Address: </p>
            <p>{props.site.siteIp}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Name: </p>
            <p>{siteName}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Address:</p>
            <p>{props.site.siteAddress}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Coordinates:</p>
            {props.site.latitude && props.site.longitude && (
            <p>
              {props.site.latitude}, {props.site.longitude}{" "}
              <Link to={`/map`}>
                {" "}
                <i className="fas fa-map-marker-alt"></i>See map
              </Link>
            </p>
            )}
          </div>
          <div className="detail-item">
            <p className="detail">Last Heartbeat: </p>
            <p>{props.site.heartbeat}</p>
          </div>
          <div>
            <p id="access-notes-label" className="detail">
              Access Notes:
            </p>
            <p className="wrapping-text">{props.site.accessNotes}</p>
          </div>
        </div>

        <div className="alarm-detail-section">
          <p className="heading">CONTACT</p>
          <div className="detail-item">
            <p className="detail">Contact:</p>
            <p>{props.site.contactName}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Email:</p>
            <p>{props.site.email}</p>
          </div>
          <div className="detail-item">
            <p className="detail">Phone:</p>
            <p>{props.site.phone}</p>
          </div>
          <div>
            <p id="contact-notes-label" className="detail">
              Contact Notes:
            </p>
            <p className="wrapping-text">{props.site.contactNotes}</p>
          </div>
        </div>

        <div className="alarm-detail-section">
          {id && fileTable && (
            <div>
              <p className="heading">FILES <i id="add-file-icon" onClick={openAddFileForm} className="fas fa-plus"></i></p>
              <FileTable siteId={id} updateFileStorageInfo={updateFileStorageInfo} openEditFileDescForm={openEditFileDescForm}/>
            </div>
          )}

          {id && addFileForm && (
            <div>
              <p className="heading"> NEW FILE </p>
              <NewFileUpload siteId={id} closeForm={openFileTable} fileStoragePlan={props.site.fileStoragePlan} fileStorageRemaining={fileStorageRemaining}/>
            </div>
          )}

          {id && editFileDescForm && (
            <div>
              <p className="heading"> FILE DETAILS </p>
              <FileDescriptionEdit fileId={fileToEditId} closeForm={openFileTable} fileName={fileToEditName} fileSize={fileToEditSize} fileDesc={fileToEditDesc}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SiteDetailCards); 