import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/alarmDetail.css";
import MainHeader from "../MainHeader";
import { modifyUTCToLocalTime } from "../../utils/helpers";
import { getAlarmDetails, postAlarmNote } from "../../utils/apis";

function AlarmDetail() {

  const { alarmId } = useParams();

  const [notes, setNotes] = useState("");
  const [alarmDetails, setAlarmDetails] = useState("");

  const [formState, setFormState] = useState({
    newNote: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { newNote } = formState;
  const { messageText, messageColor } = message;

  useEffect(() => {
    storeAlarmDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storeAlarmDetails = async () => {

    const alarmDetailsData = await getAlarmDetails(alarmId);

    if (alarmDetailsData) {
        
      const alarmDetails = alarmDetailsData.alarmDetails;
      let notes = alarmDetailsData.notes;

      if (alarmDetails.start_time) {
        alarmDetails.start_time = modifyUTCToLocalTime(alarmDetails.start_time);
      }
      if (alarmDetails.end_time) {
        alarmDetails.end_time = modifyUTCToLocalTime(alarmDetails.end_time);
      }

      if (alarmDetails.system_heartbeat) {
        alarmDetails.system_heartbeat = modifyUTCToLocalTime(
          alarmDetails.system_heartbeat
        );
      }
      
      if (alarmDetails.site_heartbeat) {
        alarmDetails.site_heartbeat = modifyUTCToLocalTime(
          alarmDetails.site_heartbeat
        );
      }
        
      if (notes) {
        notes = notes.map((note) => {
          note.date = modifyUTCToLocalTime(note.date);
          return note;
        });
        setAlarmDetails(alarmDetails);
        setNotes(notes);
      } else {
        setAlarmDetails(alarmDetails);
      }
    } else {
      setAlarmDetails("");
      setNotes("")
    }
  };

  const getAlarmStatus = () => {
    const alarmMinutes = alarmDetails.duration;
    const days = parseInt(alarmMinutes / (60 * 24));
    const hours = parseInt((alarmMinutes % (60 * 24)) / 60);
    const mins = parseInt(alarmMinutes % 60);

    const alarmDuration = `${days} days, ${hours} hrs, ${mins} mins`;

    if (alarmDetails.status === "Clear") {
      return `${alarmDetails.status} ( Ended: ${alarmDetails.end_time} - Duration: ${alarmDuration}) `;
    }
    return alarmDetails.status;
  };

  const handleAddNote = (note) => {
    setFormState({ ...formState, newNote: note });
  };

  const saveNote = async (note) => {
    let updatedNotes = await postAlarmNote(alarmDetails.id, note);
    if (updatedNotes) {
      updatedNotes = updatedNotes.map((note) => {
        note.date = modifyUTCToLocalTime(note.date);
        return note;
      });
      setNotes(updatedNotes);
    }
  };

  const onSubmitNote = (event) => {
    event.preventDefault();
    if (newNote === "") {
      setMessage({ messageText:"Please enter a note.", messageColor: "red" });
    } else if (newNote.length > 1000) {
      setMessage({ messageText: `Your note must be below 1000 characters. Your note is ${newNote.length} characters.`, messageColor: "red" });
    } else {
      saveNote(newNote);
      setFormState({ ...formState, newNote: "" });
      setMessage({ messageText: "", messageColor: "" })
    }
  };

  return (
    <div className="main main-detail">
      <MainHeader componentName="Alarm Details" />
      <div className="alarm-detail-section">
        <p className="heading">ALARM</p>
        <div className="detail-item">
          <p className="detail">Start Time:</p>
          <p>{alarmDetails.start_time}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Status:</p>
          <p>{getAlarmStatus()}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Level:</p>
          <p>{alarmDetails.level}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Event:</p>
          <p>{alarmDetails.title}</p>
        </div>
        <div>
          <p id="description-label" className="detail">
            Description:
          </p>
          <span className="wrapping-text last-text-in-detail">
            {alarmDetails.alarm_description}
          </span>
        </div>
      </div>

      <div className="alarm-detail-section">
        <p className="heading">ALARM NOTES</p>

        {!notes && <p>No Notes</p>}
        {notes &&
          notes.map((note, i) => {
            return (
              <div key={i}>
                <div className="note-card">
                  <div className="source">
                    <p className="author">{note.author}</p>
                    <p className="note-datetime">{note.date}</p>
                  </div>
                  <p className="content">
                    <span>{note.note}</span>
                  </p>
                </div>
              </div>
            );
          })}

        <form className="alarm-note-form" onSubmit={onSubmitNote}>
          <div id="note-label" className="detail">
            Enter an Alarm Note:
          </div>
          <textarea
            className="alarm-note-input"
            value={newNote}
            onChange={(event) => handleAddNote(event.target.value)}
            type="text"
            maxLength="1000"
            placeholder="Enter an alarm note."
          />
          <button className="submit-alarm-note" type="submit">
            Submit
          </button>

          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </form>
      </div>

      <div className="alarm-detail-section">
        <p className="heading">LINKS</p>
        <p>
          <a
            href={alarmDetails.system_hyperlink + "/?csj=" + window.sessionStorage.getItem('CSJ5410')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-link"></i>Remote Access
          </a>
        </p>
      </div>

      <div className="alarm-detail-section">
        <p className="heading">SYSTEM</p>
        <div className="detail-item">
          <p className="detail">ID:</p>
          <p>System {alarmDetails.equip_id}</p>
        </div>
        <div className="detail-item">
          <p className="detail">IP Address:</p>
          <p>{alarmDetails.ip_address}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Name:</p>
          <p>{alarmDetails.system_name}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Manufacturer:</p>
          <p>{alarmDetails.manufacturer}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Model:</p>
          <p>{alarmDetails.model}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Port:</p>
          <p>{alarmDetails.port}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Last Heartbeat:</p>
          <p>{alarmDetails.system_heartbeat}</p>
        </div>
        <div className="detail-item last-detail-item">
          <p id="location-notes-label" className="detail">
            Location Notes:
          </p>
          <span className="wrapping-text last-text-in-detail">
            {alarmDetails.location_notes}
          </span>
        </div>
      </div>

      <div id="last-alarm-detail-section" className="alarm-detail-section">
        <p className="heading">SITE</p>
        <div className="detail-item">
          <p className="detail">ID:</p>
          <p>{alarmDetails.mac_id}</p>
        </div>
        <div className="detail-item">
          <p className="detail">IP Address:</p>
          <p>{alarmDetails.site_ip}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Name:</p>
          <p>{alarmDetails.site_name}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Address:</p>
          <p>{alarmDetails.site_address}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Coordinates:</p>
          {alarmDetails.latitude && alarmDetails.longitude && (
          <p>
            {alarmDetails.latitude},{" "}
            {alarmDetails.longitude}{" "}
            <Link to={`/map`}>
              {" "}
              <i className="fas fa-map-marker-alt"></i>See map
            </Link>
          </p>
            )}
        </div>
        <div className="detail-item">
          <p className="detail">Last Heartbeat:</p>
          <p>{alarmDetails.site_heartbeat}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Contact:</p>
          <p>{alarmDetails.contact_name}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Email:</p>
          <p>{alarmDetails.email}</p>
        </div>
        <div className="detail-item">
          <p className="detail">Phone:</p>
          <p>{alarmDetails.phone}</p>
        </div>
        <div className="detail-item">
          <p id="contact-notes-label" className="detail">
            Contact Notes:
          </p>
          <p className="wrapping-text">
            {alarmDetails.contact_notes}
          </p>
        </div>
        <div className="detail-item last-detail-item">
          <p id="access-notes-label" className="detail">
            Access Notes:
          </p>
          <p className="wrapping-text">
            {alarmDetails.access_notes}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AlarmDetail;